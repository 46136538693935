.CardPersonHolder {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;

    .CardPerson {
        // Warning: a higher number outputs more CSS.

        $colors: (1: rgb(9, 108, 238), 2: orange, 3: teal, 4: green, 5: blue, 6: purple, 7: navy, 8: teal, 9: fuchsia, 10: silver, 11: maroon, 12: aqua);

        @each $i,
        $color in $colors {

            $darkColor: darken($color, 15%);

            &:nth-child(#{$i}) .card .ds-top {
                background: $color;

                $l1: adjust-hue($color, 30deg);
                $l2: adjust-hue($color, 40deg);
                $l3: adjust-hue($color, 50deg);
                $l4: adjust-hue($color, 70deg);

              //  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
                background-image: linear-gradient(to right top, $color, $l1, $l2,  $l3, $l4);
            }


            &:nth-child(#{$i}) .card:hover {

                background: linear-gradient(180deg, #000 0%, $darkColor 180%);
            }


            &:nth-child(#{$i}) .card .ds-skill .essence-holder .essence .icon {

                background: linear-gradient(180deg, #000 0%, $darkColor 130%);


            }

            &:nth-child(#{$i}) .card .ds-skill .essence-holder .essence:hover .icon {

                background: linear-gradient(180deg, $color 0%, $darkColor 130%);

            }


        }

    }

}