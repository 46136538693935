@import '../../../../styles/mixins.scss';

.CartPaymnetCmp {

    border-radius: var(--radius);
    border: 2px solid var(--border-theme);
    background-color: var(--primary-l1);
    padding: 15px 15px 15px 15px;
    width: fit-content;
    height: 100%;
    min-width: 340px;

    .title {
        font-weight: bold;
        line-height: 1rem;
        margin-bottom: 12px;
    }

    .paymentMethod {
        display: grid;
        grid-template-columns: 25px 70px auto;
        grid-template-rows: 30px;
        grid-template-areas: "selector pic text";
        column-gap: 5px;
        margin-bottom: 10px;

        .pic_holding {
            grid-area: pic;
            height: 30px;
            width: 70px;
            border: 1px outset var(--primary-l2);
            background-color: var(--primary-l1);
            text-align: center;
            user-select: none;

            .pic {
                padding: 5px;
                max-width: 70px;
                height: 100%;
            }
        }

        .text {
            grid-area: text;
            width: auto;
            height: 30px;
            line-height: 30px;
            user-select: none;
            padding-left: 10px;
        }


        .control-radio {
            grid-area: selector;
            position: relative;
            //padding-left: 30px;
            //margin-bottom: 10px;
            //padding-top: 5px;
            cursor: pointer;

            input {
                z-index: -1;
                opacity: 0;
                display: none;

                &:checked~.control_indicator {
                    background-color: var(--secondary);
                }

                &:checked~.control_indicator:after {
                    display: block;
                }

                &:checked+.control_indicator::before {
                    animation-name: s-ripple-dup;
                }

                &+.control_indicator::before {
                    animation: s-ripple 250ms ease-out;
                }

                &:disabled~.control_indicator {
                    background-color: #e6e6e6;
                    opacity: 0.6;
                    pointer-events: none;
                }

                &:disabled~.control_indicator:after {
                    background-color: var(--disabled);
                }


            }

            /* &:hover input:not([disabled]):checked~.control_indicator,
            .control input:checked:focus~.control_indicator {
                //background-color: rgb(201, 193, 193); //0e6647d;
                background-color: var(--secondary);
            }*/

            &:hover input~.control_indicator,
            input:focus~.control_indicator {
                background-color: var(--secondary-light);
                //background: #cccccc;
            }

            .control_indicator {

                position: absolute;
                border-radius: 50%;
                top: 5px;
                left: 0px;
                height: 20px;
                width: 20px;
                border: 1px solid var(--secondary-dark);
                background-color: var(--secondary);

                &:after {
                    display: none;
                    position: absolute;
                    //box-sizing: unset;
                    content: '';
                    left: 5px;
                    top: 5px;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    transition: background 250ms;
                }

                &::before {
                    content: '';
                    display: block;
                    // position: absolute;
                    // left: 0;
                    // top: 0;
                    width: 4.5rem;
                    height: 4.5rem;
                    margin-left: -1.6rem;
                    margin-top: -1.6rem;
                    background: var(--secondary-light);
                    border-radius: 3rem;
                    opacity: 0.6;
                    z-index: 99999;
                    transform: scale(0);
                }

            }

        }


    }

    @keyframes s-ripple {
        0% {
            opacity: 0;
            transform: scale(0);
        }

        20% {
            transform: scale(1);
        }

        100% {
            opacity: 0.01;
            transform: scale(1);
        }
    }

    @keyframes s-ripple-dup {
        0% {
            transform: scale(0);
        }

        30% {
            transform: scale(1);
        }

        60% {
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1);
        }
    }

    @include mobile {
        width: 100%;
    }

}