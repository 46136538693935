@import '../../../styles/mixins.scss';

.CardRecipe {
    position: relative;
    width: 350px;
    margin: 0px;

    .card {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "nav"
            "oils"
            "description"
            "footer";
        cursor: pointer;

        // height: 375px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        transition: all 0.3s;
        border-radius: var(--radius);

        $color: #adbff7;
        $l1: adjust-hue($color, 10deg);
        $l2: adjust-hue($color, -20deg);
        $l3: adjust-hue($color, 20deg);
        $l4: adjust-hue($color, -30deg);

        $l4i: adjust-hue($color, 30deg);
        $l2i: adjust-hue($color, 20deg);
        $l00: darken($color, 5);

        $l01: darken($color, 45);

        //  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
        background-image: linear-gradient(to right bottom, $color, $l1, $l2, $l3, $l4);

        &:hover {

            box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        }

        .recipe_nav {
            grid-area: nav;
            padding: 1.5rem;
            border-bottom: 3px solid $l00;

            text-align: center;
            height: 4rem;


            h2 {
                margin: 0;
                color: $l01;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1rem;
            }

        }

        
        .recipe_description {
            grid-area: description;
            padding: 15px;
            border-top: 3px solid $l00;

            text-align: left;
            height: 10rem;


            h2 {
                margin: 0;
                color: $l01;
                font-weight: normal;
                font-size: 1rem;
            }

        }

        .recipe_footer {
            grid-area: footer;
            padding: 15px;
            border-top: 3px solid $l00;
            height: 4rem;

            text-align: left;


            h2 {
                margin: 0;
                color: $l01;
                font-weight: normal;
                font-size: 1rem;
            }
            

        }

        .OilBottle {
            transform: scale(0.55);
            height: 90px;
        }

        .recipe_oils {
            grid-area: oils;

            height: 320px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            //gap: 20px 10px;

            width: 100%;
            text-align: center;


        }

    }


    @include mobile {
        width: 95%;
    }


}