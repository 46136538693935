.ProfilePage {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
    width: 100%;
      
    .holder_content{
        padding: 15px;
    }

    .grid {
        display: block;
        text-align: center;

        .title {
            width: 100%;
            padding: 10px 0px 10px 0px;
            text-align: left;


            &:first-child{
                padding: 0px 0px 10px 0px;
            }
        }

        .person_display_name {
            width: 100%;
            max-width: 320px;
        }

        .person_name {
            width: 100%;
            max-width: 320px;
        }

        .person_last_name {
            width: 100%;
            max-width: 320px;
        }

        .email {
            width: 100%;
            max-width: 320px;
        }
        
        .phone {
            width: 100%;
            max-width: 320px;
        }

        .actions {
            width: 100%;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 5px;

            .save_btn {
                margin: 0 auto;
                min-width: 150px;
            }

        }
    }
}