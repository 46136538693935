@import '../../../styles/mixins.scss';

.HamburgerMenuCmp {
    display: none;
    float: right;
    min-width: 20px;
    cursor: pointer;
    font-size: 1.3rem;
    margin-right: 15px;
    margin-left: 10px;
    line-height: 2.2rem;
    margin-top: 3px;

    .hamburger_icon {
        display: inline-block;
        cursor: pointer;

        .bar1,
        .bar2,
        .bar3 {
            width: 35px;
            height: 4px;
            background-color: var(--primary-light);
            margin-top: 5px;
            transition: 0.4s;
        }

        

    }

    .change {
        .bar1 {
            transform: rotate(-45deg) translate(-6px, 6px);
        }

        .bar2 {
            opacity: 0;
        }

        .bar3 {
            transform: rotate(45deg) translate(-6px, -8px);
        }

    }


    .hamburger_content {
        display: block;
        position: absolute;
        cursor: pointer;
        user-select: none;
        float: left;
        left: 0px;
        text-align: left;

        margin: 0px;
        padding: 0px;
        width: 100vw;
        min-width: max-content;
        max-width: 90px;
        margin: 0px 0px 0px 0px;
        height: auto;

        border-bottom: 4px solid var(--secondary);
        background-color: var(--primary-dark);
        color: var(--primary-light-text);

        &:hover {

            background-color: var(--secondary);
            color: var(--secondary-text);
        }


        .dropdown_content {
            width: 100%;
        }

        .dropdown_elements {
            display: block;
            max-height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;
        }

        a,
        p,
        .pline {
            display: block;
            width: 100vw;
            padding: 0px 0px 0px 15px;
            text-align: left;

            &:last-child:not(:first-child) {
                border-radius: 0px 0px var(--radius-bigger) var(--radius-bigger);
            }

            &:not(last-child) {
                border-radius: 0px 0px 0px 0px;
            }
        }

        p {
            text-align: left;
        }

        img {
            margin: 0px;
            padding: 0px;
            width: 13px;
            height: 13px;
        }

        .dropdown_content {
            display: block;
            position: fixed;
            border-bottom: 4px solid var(--secondary);
            border-radius: 0px 0px var(--radius) var(--radius);
            background-color: var(--secondary);
            color: var(--primary-text);
            padding: 0px 0px 0px 0px;
            z-index: 11;
            max-height: 80vh;
            height: auto;
            animation: fadeIn 0.8s;

            .dropdown_elements {
                display: block;
                max-height: 80vh;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .pline {
                display: block;
                min-width: 160px;
                text-align: left;
                height: 40px;
                line-height: 40px;
                padding: 0px 35px 0px 15px;


                &:hover {
                    color: var(--secondary-text);
                    border-bottom: 4px solid var(--secondary);
                }

                &:last-child {
                    border-radius: 0px 0px var(--radius-smaller) var(--radius-smaller);
                }

            }

        }

    }

    @include tablet {
        display: block;
    }

}