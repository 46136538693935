.ChangeLangCmp {
    user-select: none;
    border-radius: var(--radius);
    min-width: 20px;
    min-height: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.9;
    
}