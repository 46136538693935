@import '../../../../styles/mixins.scss';

.BasicListingPage {
    width: 100%;
    max-width: 1200px;  
    text-align: center;
    margin: 0px auto 10px auto;


    @include tablet {



        
    }


}