@import '../../../../styles/mixins.scss';

.listing_page {
    width: 100%;
    margin-bottom: 25px;
    text-align: center;

    .product_tile_holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 12px;
    }

    .product_tile {
        position: relative;
        flex-grow: 1;
        background-color: var(--primary);
        color: var(--primary-text);
        border: 1px solid var(--border-theme);
        border-radius: var(--radius);
        text-align: center;
        // padding: 8px 8px 8px 8px;
        width: 320px;
        box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.5);
        margin-bottom: 15px;

        &:hover {

            border-color: var(--primary-l1);

            .product_action_buttons {
                opacity: 1;
            }

            .product_name {
                opacity: 1;
            }

            .tags_holder {
                opacity: 1;
            }

        }

        &:not(:last-child):not(:nth-child(3n+0)) {
            margin-right: 12px;
        }

        .tags_holder {
            position: absolute;
            //opacity: 0.5;
            transition: all 0.3s ease-in;
            padding: 5px;

            .new,
            .bestseller,
            .promotion,
            .favorite {
                display: inline-block;
                min-width: 50px;
                margin-right: 5px;
                text-align: center;
                vertical-align: middle;
                border-radius: var(--radius);
                color: var(--primary-dark);
                border: 1px solid;
                line-height: 1.6rem;
                height: 1.6rem;
                font-size: 0.8rem;
                padding: 0px;
            }

            .new {
                border-color: rgb(0, 199, 0);
                background-color: rgba(0, 199, 0, 0.08);
            }

            .bestseller {
                border-color: rgb(97, 97, 255);
                background-color: rgba(97, 97, 255, 0.08);
            }

            .promotion {
                border-color: rgb(255, 44, 44);
                background-color: rgba(255, 44, 44, 0.08);
            }

        }

        .tags_holder2 {
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 2;
            transition: all 0.3s ease-in;
            padding: 0px;

            .favorite {
                display: inline-block;
                min-width: 24px;
                top: 0px;
                right: 0px;
                margin: 0px;
                border: unset;
                opacity: 0.5;
                height: 1.6rem;
                line-height: 1.6rem;
                font-size: 0.9rem;

                &:hover {
                    opacity: 1;
                    filter: brightness(120%);
                }

                &.checked {
                    opacity: 1;
                    //filter: brightness(100%);
                }
            }

        }

        .product_img_holder {
            width: 100%;
            height: auto;
            background-color: white;
            border-radius: var(--radius) var(--radius) 0 0;
            margin-bottom: 5px;
            cursor: pointer;

            .product_img {
                width: auto;
                height: 240px;
                max-width: 100%;
                padding-top: 10px;
                transition: all 0.3s ease-in;
                object-fit: cover;
            }
        }

        .product_name {
            display: inline-block;
            width: 100%;
            //max-width: 300px;
            line-height: 1.2rem;
            height: 3.6rem;
            font-size: 1.0rem;

            margin: 5px 0px 5px 0px;
            transition: opacity 0.3s ease-in;
            word-wrap: break-word;
            text-align: center;
            padding: 0px 10px;

        }

        .product_regular_price,
        .product_price,
        .product_promo_price {
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            font-size: 1.0rem;
            line-height: 1.4rem;
            height: 1.4rem;
            font-weight: 600;
            text-align: center;

            padding: 0px 10px;

        }

        .product_regular_price {
            width: auto;
            font-size: 0.8rem;
            text-decoration: line-through;
            color: var(--always-red);
        }

        .product_price {
            display: block;
            color: var(--primary-text);
        }

        .product_promo_note {
            display: inline-block;
            //width: 100%;
            font-size: 0.8rem;
            height: 1.2rem;
            color: #be7070;
            // margin-top: 5px;
            margin-right: 5px;
        }

        .product_action_buttons {
            display: block;
            margin-top: 12px;
            text-align: center;
            opacity: 0.4;
            transition: opacity 0.3s ease-in;

            button {
                min-width: 60px;
                height: 2.5rem;
                width: 100%;
                user-select: none;
                border-radius: 0 0 var(--radius) var(--radius);
            }
        }



    }

    @include mobile {
        .product_tile_holder {
            display: block;

            .product_tile {
                display: block;
                width: 95%;
                margin: 0px auto 10px auto;

                .product_action_buttons {
                    opacity: 1;
                }

                .product_name {
                    opacity: 1;
                }

                .tags_holder {
                    opacity: 1;
                }


                &:not(:last-child):not(:nth-child(3n)) {
                    margin: 0px auto 10px auto;
                }

                // &:not(:first-child){
                //     margin: 0px auto 0px auto;
                // }
            }


        }

    }

}