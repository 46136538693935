@import '../../../styles/mixins.scss';

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 110;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw;
    /* Full width */
    height: 100vh;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */

    /* Modal Content/Box */
    .modal-content {
        /* 15% from the top and centered */
        display: block;
        margin: 10% auto 0px auto;
        width: 80%;
        height: auto;
        border-radius: var(--radius);
        z-index: 111;
        box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.5);
        /* Could be more or less, depending on screen size */

    }

    /* The Close Button */
    .close {
        color: var(--secondary-text);
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: var(--primary-dark);
        text-decoration: none;
        cursor: pointer;
    }


    /* Modal Header */
    .modal-header {
        padding: 0.5rem 16px;
        background-color: var(--secondary);
        color: var(--secondary-text);
        border-radius: var(--radius) var(--radius) 0px 0px;
        min-height: 2rem;
    }

    /* Modal Body */
    .modal-body {
        padding: 2px 16px;
        background-color: var(--always-white);
        color: var(--always-black);
        min-height: 120px;

    }

    /* Modal Footer */
    .modal-footer {
        padding: 2px 16px;
        background-color: var(--secondary);
        color: var(--secondary-text);
        border-radius: 0px 0px var(--radius) var(--radius);
        min-height: 2rem;
        border-top: 1px solid #dee2e6;

    }


    /* Add Animation */
    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    @include tablet {
        .modal-content {
            width: 95%;
            margin: 10% auto;
        }
    }

}