
$theme-path: "../assets/images/core/";
[data-theme="dark_fusion"]  {
    --transparent: rgba(0, 0, 0, 0);
    --always-black : rgb(0, 0, 0);
    --always-white : rgb(255, 255, 255);
    --always-grey-l1 : rgb(197, 197, 197);
    --always-red : rgb(255,0,0);

    /*Theme name */
    --primary-l5: #f3f4f5;
    --primary-l4: #d7dbde;
    --primary-l3: #aeb7bd;
    --primary-l2: #86939c;
    --primary-l1: #616e77; 

    --primary: #414a50;
    
    --primary-d1: #3a4247;
    --primary-d2: #333a3f;
    --primary-d3: #2d3337;
    --primary-d4: #272c2f;
    --primary-d5: #202427; 
    
    --border-theme: #40404f;
    --border-theme-light: #86939c;
    
    --primary-light: #20aaff;
    --primary-dark: #115c8a;

    --secondary: #1976d2;
    --secondary-light: #63a4ff;
    --secondary-dark: #004ba0;

    --primary-text: #e7e7e7;
    --primary-light-text: #bbbbbb;
    --primary-dark-text: #8d8d8d;
    
    --secondary-text: #ffffff;
    --secondary-light-text: #000000;
    --secondary-dark-text: #ffffff;

    --disabled: #7b7b7b;
    --disabled-text: #b9b8b8;

    --valid: rgb(41, 175, 41);
    --invalid: rgb(180, 71, 38);

    /*Borders*/
    --radius: 9px;
    --radius-smaller: 5px;
    --radius-bigger: 11px;

    --font-size: 14px;
    --font-size--small: 0.8rem;
    --font-size--large: 1.2rem;

}

.dark_fusion {

    box-sizing: border-box;
    background: url('#{$theme-path}background_l.png') 0px 0px repeat-y,
    url('#{$theme-path}background_r.png') 100% 100% repeat-y;
    background-color: var(--primary);
}