.FiltersHolder {

    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    gap: 5px;
    height: 30px;
    
    margin: 10px 0px;
    padding-left: 10px;

    .filterTag {
        font-size: 0.9rem;
        font-weight: bold;
        user-select: none;

    }
}