.ButtonFun {
    user-select: none;
    border-radius: var(--radius);
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    vertical-align: middle;
    outline: 0;
    border: 0;

    &.flip {
        animation: flip-scale-up-ver 0.5s ease-out both;
    }

    &.backflip {
        animation: flip-scale-up-ver 0.5s reverse both;
    }

    /**
    * ----------------------------------------
    * animation flip-scale-up-ver
    * ----------------------------------------
    */
    @keyframes flip-scale-up-ver {
        0% {
            transform: scale(1) rotateY(0);
        }

        50% {
            transform: scale(2.5) rotateY(90deg);
        }

        100% {
            transform: scale(1) rotateY(180deg);
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }


}