.Filter {

    $size: 30px;


    display: grid;
    grid-template-columns: 25px minmax(max-content, 70px);
    grid-template-areas: "close text";
    position: relative;
    width: fit-content;
    height: $size;

    text-align: center;
    margin: 0px 0px 0px 0px;

    overflow: hidden;
    border-radius: 0px var(--radius); //var(--radius);

    cursor: pointer;
    box-shadow: 6px 6px 12px -5px rgba(66, 68, 90, 1);

    $color: rgb(28, 170, 127);
    $l1: adjust-hue($color, 30deg);
    $l2: adjust-hue($color, 40deg);
    $l3: adjust-hue($color, 50deg);
    $l4: adjust-hue($color, 70deg);

    //  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    background-image: linear-gradient(to right bottom, $color, $l1, $l2, $l3, $l4);

    &:hover {
        .close {
            color: rgb(30, 0, 70);
        }
    }

    .close {
        grid-area: close;
        color: white;
        line-height: $size;
        text-align: center;
        cursor: pointer;
        user-select: none;


    }

    .text {
        font-size: 0.8rem;
        grid-area: text;
        line-height: 30px;
        color: white;
        padding: 0px 10px 0px 0px;
        user-select: none;
    }

}