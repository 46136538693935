/* Style the header */
.HeadBannerCmp {
    .header {
        display: grid;
        grid-template-columns: 50px auto 30px 10px 50px;
        grid-template-rows: 50px;
        grid-template-areas: "logo title lang . theme";
        background: var(--secondary);
       //FIX background-image: 'url("/images/core/pattern_pentagon.png")';
        background-repeat: repeat-x;
        width: 100%;
        min-width: 250px;
        padding: 5px 10px 5px 10px;
        cursor: pointer;

        * {
            color: var(--secondary-text);
        }

        .click_header {
            grid-area: logo;
            height: 100%;

            img {
                width: auto;
                height: 100%;
                //border-radius: 50%;
                //border-color: var(--border-theme);
                //border-style: none;
                //border-width: 2px;
                padding: 8px;
            }

        }

        .title {
            grid-area: title;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;


            //Increase the font size of the h1 element
            h1 {
                font-size: var(--font-size--h1);
                font-weight: bold;
            }

            a {
                padding: 10px;
                width: auto;
                height: 100%;
                text-decoration: none;
            }

            p {
                margin-top: 5px;
                font-size: 0.8rem;
                line-height: 0.8rem;
                font-weight: bold;
                font-style: italic;
            }
        }

        .lang_switch {
            grid-area: lang;
            width: auto;
            margin-top: auto;
            margin-bottom: auto;
        }

        .theme_switch {
            grid-area: theme;
            border: none;
            width: auto;
            margin-top: auto;
            margin-bottom: auto;
        }


    }
}