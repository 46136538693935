.GiftcardSelectionCmp{


    select {
        line-height: 2rem;
        font-size: 2rem;
        text-align: center
    }



}