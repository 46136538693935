@import '../../../../styles/mixins.scss';

/* Style the top navigation bar */
.MyAccountMenuCmp {

    float: right;

    .dropdown_content {
        width: 100%;
    }


    .text {

        display: block;

    }


    @include mobile {

        .text {

            display: none;

        }

    }

}