@import '../../../styles/mixins.scss';

.CardOils {
    position: relative;
    width: 222px;
    margin: 0px;

    .card {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "nav"
            "img";
        cursor: pointer;

        // height: 375px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        transition: all 0.3s;
        border-radius: var(--radius);

        $color: #adbff7;
        $l1: adjust-hue($color, 10deg);
        $l2: adjust-hue($color, -20deg);
        $l3: adjust-hue($color, 20deg);
        $l4: adjust-hue($color, -30deg);

        $l4i: adjust-hue($color, 30deg);
        $l2i: adjust-hue($color, 20deg);
        $l00: darken($color, 5);

        $l01: darken($color, 45);

        //  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
        background-image: linear-gradient(to right bottom, $color, $l1, $l2, $l3, $l4);

        &:hover {

            box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            .photo img {
                transform: scale(1.1) translate(0, -0.25rem);
            }

        }

        nav {
            grid-area: nav;
            padding: 1.5rem;
            border-bottom: 3px solid $l00;

            text-align: center;
            height: 4rem;


            h2 {
                margin: 0;
                color: $l01;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1rem;
            }

        }

        .photo {

            grid-area: img;

            padding: 15px;
            width: 100%;
            text-align: center;

            img {
                height: 240px;
                width: auto;


            }

        }

    }

    @include mobile {
        width: 95%;
    }


}