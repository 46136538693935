@import '../../../styles/mixins.scss';

.GlowCircle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;

    margin: 0px;
    background-color: var(--secondary-dark);
    height: 80vh;
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to right bottom,#c9cae6,#9b9fe1,#a3a6df,#9b9fe1,#c9cae6);
    border-radius: var(--radius);
    margin: 15px 0px;

    .holder {

        width: 70vh;
        height: 70vh;

        .img {

            position: absolute;
            filter: unset;
            top: 30%;
            left: 45%;
            left: calc( 50% - 30px );
            max-width: 360px;
            height: 200px;

        }

        .circle {
            position: relative;
            width: 100%;
            height: 100%;
            filter: url(#wavy) blur(1px);

            &::before {
                content: '';
                position: absolute;
                top: 10%;
                left: 10%;
                right: 10%;
                bottom: 10%;
                border: 20px solid #fff;
                border-radius: 50%;
                box-shadow: 3 3 50px #0f0,
                    inset 0 0 50px #0f0;
                animation: animate 5s linear infinite;
            }

            &::after {
                content: '';
                position: absolute;
                top: 10%;
                left: 10%;
                right: 10%;
                bottom: 10%;
                border: 20px solid #fff;
                border-radius: 50%;
                box-shadow: 0 0 40px #fff,
                    inset 0 0 90px #fff;
            }


        }

        svg {

            width: 0px;
            height: 0px;

        }
    }

    @keyframes animate {


        0% {
            box-shadow: 0 0 50ox #0f0,
                inset 0 0 50px #0f0;
            //  filter: hue-rotate(0deg);

        }

        20% {
            box-shadow: 0 0 60ox #0f0,
                inset 0 0 60px #0f0;

        }

        40% {
            box-shadow: 0 0 40ox #0f0,
                inset 0 0 40px #0f0;
        }

        60% {
            box-shadow: 0 0 80ox #0f0,
                inset 0 0 80px #0f0;
        }

        80% {
            box-shadow: 0 0 100ox #0f0,
                inset 0 0 100px #0f0;
        }

        100% {
            box-shadow: 0 0 50ox #0f0,
                inset 0 0 50px #0f0;
            filter: hue-rotate(360deg);

        }

    }

























}