@import '../../../../styles/mixins.scss';

.OrdersPage {
    .productEntries {
        background-color: var(--primary-l2);
        color: var(--primary-text);
        border: 2px inset var(--border-theme);
        border-radius: var(--radius);

        margin: 10px 0px;

        .title {
            line-height: 2rem;
            font-weight: bold;
            padding: 0px 15px 0px 15px;
            width: 100%;
            border-bottom: 1px solid var(--border-theme);
            border-radius: var(--radius) var(--radius) 0 0;
            background-color: var(--primary-l1);
        }

    }

    .oderEntry {
        display: grid;
        grid-template-columns: 110px 110px 170px 170px 120px;
        grid-template-rows: 1.4rem 1.4rem;
        //grid-row-gap: 5px;
        grid-template-areas:
            "dateTag realizationTag orderNoTag statusTag valueTag"
            "date realization orderNo status value";

        background-color: var(--primary-l1);
        color: var(--primary-text);
        border-top: 1px solid var(--border-theme);
        border-bottom: 1px solid var(--border-theme);
        //border-radius: var(--radius);

        cursor: pointer;

        margin: 12px 0px;
        padding: 8px; //10px 0px 5px 10px;
        transition: color 0.4s ease-out;

        .dateTag {
            grid-area: dateTag;
            border-right: 1px solid var(--border-theme-light);
            border-bottom: 1px solid var(--border-theme-light);
            color: var(--primary-light-text);
            font-size: 0.8rem;
            padding-left: 5px;
            line-height: 1.4rem;
        }

        .realizationTag {
            grid-area: realizationTag;
            border-right: 1px solid var(--border-theme-light);
            border-bottom: 1px solid var(--border-theme-light);
            color: var(--primary-light-text);
            font-size: 0.8rem;
            padding-left: 5px;
            line-height: 1.4rem;
        }

        .orderNoTag {
            grid-area: orderNoTag;
            border-right: 1px solid var(--border-theme-light);
            border-bottom: 1px solid var(--border-theme-light);
            color: var(--primary-light-text);
            font-size: 0.8rem;
            padding-left: 5px;
            line-height: 1.4rem;
        }

        .valueTag {
            grid-area: valueTag;
            border-right: 1px solid var(--border-theme-light);
            border-bottom: 1px solid var(--border-theme-light);
            color: var(--primary-light-text);
            font-size: 0.8rem;
            padding-left: 5px;
            line-height: 1.4rem;
        }

        .statusTag {
            grid-area: statusTag;
            border-right: 1px solid var(--border-theme-light);
            border-bottom: 1px solid var(--border-theme-light);
            color: var(--primary-light-text);
            font-size: 0.8rem;
            padding-left: 5px;
            line-height: 1.4rem;
        }

        .showTag {
            grid-area: showTag;
            border-right: 1px solid var(--border-theme-light);
            border-bottom: 1px solid var(--border-theme-light);
            color: var(--primary-light-text);
            font-size: 0.8rem;
            padding-left: 5px;
            line-height: 1.4rem;
        }


        .orderNo {
            grid-area: orderNo;
            text-align: left;
            line-height: 1.4rem;
            padding-left: 5px;
            border-right: 1px solid var(--border-theme-light);

        }

        .date {
            grid-area: date;
            text-align: left;
            line-height: 1.4rem;
            padding-left: 5px;

            border-right: 1px solid var(--border-theme-light);

        }

        .realization {
            grid-area: realization;
            text-align: left;
            line-height: 1.4rem;
            padding-left: 5px;

            border-right: 1px solid var(--border-theme-light);

        }

        .value {
            grid-area: value;
            text-align: right;
            line-height: 1.4rem;
            padding-right: 5px;

            border-right: 1px solid var(--border-theme-light);


        }

        .status {
            grid-area: status;
            text-align: left;
            line-height: 1.4rem;
            padding-left: 5px;
            border-right: 1px solid var(--border-theme-light);


        }

        .show {
            grid-area: show;
            text-align: left;
            line-height: 1.4rem;
            padding-left: 5px;

            .showBtn {
                height: 100%;

            }
        }

        &:hover {
            box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.59);
        }

    }


    .productEntry {
        display: grid;
        grid-template-columns: 144px auto 100px 50px 100px 18px;
        grid-template-rows: 1.4rem 40px 40px 40px;
        grid-row-gap: 5px;
        grid-template-areas:
            "header header header header header header"
            "pic name unitPrice . price price"
            "pic name unitPrice qty price price"
            "pic baseUnit unitPrice . price price";

        background-color: var(--primary-l1);
        color: var(--primary-text);
        border-top: 1px solid var(--border-theme);
        border-bottom: 1px solid var(--border-theme);
        //border-radius: var(--radius);

        margin: 10px 0px;
        padding: 5px 5px;
        //background: linear-gradient(90deg, var(--primary-l2) -180%, var(--primary-l1) 50%, var(--primary-l2) 180%);


        &:hover {
            box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.59);
        }

        .pic_holder {
            grid-area: pic;
            margin-top: auto;
            margin-bottom: auto;
            height: 100%;
            width: 100%;

            .pic {
                height: 100%;
                max-width: 144px;
                width: auto;
                user-select: none;
                min-width: 50px;
            }

            /*&:hover {
                border: 1px outset var(--primary-l1);
            }*/
        }

        .name {
            grid-area: name;
            font-size: 1.2rem;
            line-height: 40px;
            padding-left: 5px;
            min-width: 80px;

            .category {
                margin-top: 5px;
                font-size: 0.8rem;
                line-height: 0.8rem;
                color: var(--primary-d3);
            }

        }

        .baseUnit {
            padding-left: 5px;
            grid-area: baseUnit;
            margin-top: auto;
            font-size: 0.8rem;
            line-height: 40px;
            color: var(--primary-d3);

        }

        .add {
            grid-area: add;
            text-align: center;
            line-height: 40px;
        }

        .qty {
            grid-area: qty;
            text-align: center;
            line-height: 40px;
            user-select: none;
            border: 1px inset var(--primary-l1);
            background-color: var(--disabeld);
        }

        .subtracts {
            grid-area: subtracts;
            text-align: center;
            line-height: 40px;
        }

        .price {
            grid-area: price;
            vertical-align: middle;
            text-align: right;
            line-height: 130px;
        }

        .unitPrice {
            grid-area: unitPrice;
            font-size: 0.9rem;
            vertical-align: middle;
            text-align: right;
            line-height: 130px;
            padding-right: 15px;
            color: var(--primary-d3);

            &::after {
                content: " x";
            }
        }

        .remove {
            grid-area: remove;
            text-align: center;
            vertical-align: top;
            line-height: 30px;


            .icon {
                cursor: pointer;
                width: 18px;
                height: 18px;
                //FIX background-image: 'url("/images/core/red_bin_48x48.png")';
                background-size: auto 100%;
                background-repeat: no-repeat;
                object-fit: contain;
            }
        }

        .actionButtonAdd,
        .actionButtonsubtracts {
            //background-color: var(--always-white);
            font-size: 1.2rem;
            padding: 0px;
            border-radius: 80%;
            width: 40px;
            height: 40px;
        }

        .actionButtonAdd {
            //color: green;
            vertical-align: top;
        }

        .actionButtonsubtracts {
            //color: red;
            vertical-align: bottom;
        }


        .header {
            grid-area: header;
            display: grid;
            grid-template-columns: 144px auto 100px 50px 100px 18px;
            grid-template-rows: 100%;
            grid-template-areas: "hpic hname basePrice hactions hprice hprice";

            background-color: var(--primary-l1);
            color: var(--primary-text);
            //border: 2px solid var(--border-theme);
            //border-radius: var(--radius) var(--radius) 0 0;
            border-bottom: 1px solid var(--border-theme-light); //--primary-light);
            color: var(--primary-light-text);
            line-height: 1rem;
            text-align: center;
            font-size: 0.8rem;
            user-select: none;


            .hpic {
                grid-area: hpic;
                border-right: 1px solid var(--border-theme-light);
                font-size: 0.8rem;
            }

            .hname {
                grid-area: hname;
                border-right: 1px solid var(--border-theme-light);
                font-size: 0.8rem;
            }

            .basePrice {
                grid-area: basePrice;
                border-right: 1px solid var(--border-theme-light);
                font-size: 0.8rem;
            }

            .hactions {
                grid-area: hactions;
                border-right: 1px solid var(--border-theme-light);
                font-size: 0.8rem;
            }

            .hprice {
                grid-area: hprice;
                font-size: 0.8rem;
            }

        }
    }


    .leadTime {
        width: 100%;
        font-size: 0.9rem;
        line-height: 0.9rem;
        background-color: var(--primary-l1);
        color: var(--primary-text);
        border-top: 1px solid var(--border-theme);
        border-radius: 0 0 var(--radius) var(--radius);
        text-align: center;
        padding: 10px;
    }


    .deliveryPaymnetHolder {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        grid-template-rows: auto;
        grid-row-gap: 10px;
        grid-template-areas: "delivery payment";

        margin-bottom: 10px;

        .delivery {
            grid-area: delivery;
            justify-self: start;
        }

        .payment {
            grid-area: payment;
            justify-self: end;
        }


    }


    .cartCoupons {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        grid-template-rows: auto;
        grid-row-gap: 10px;
        grid-template-areas: "cupons summary";

        .cupons {
            grid-area: cupons;
            justify-self: start;
        }

        .summary {
            grid-area: summary;
            justify-self: end;
        }


    }



    @include mobile {

        .deliveryPaymnetHolder {
            display: grid;
            grid-template-columns: 100%;
            grid-template-areas: "delivery"
                "payment";

            .delivery {
                justify-self: center;
                width: 100%;
            }

            .payment {
                justify-self: center;
                width: 100%;
            }
        }



        .cartCoupons {
            grid-template-columns: 100%;
            grid-template-areas: "cupons"
                "summary";

            .cupons {
                width: 100%;
            }

            .summary {
                width: 100%;
            }

        }

        .oderEntry {
            display: grid;
            grid-template-columns: 100px 170px auto;
            grid-template-rows: 1.4rem 1.4rem 1.4rem;
            grid-template-areas:
                "dateTag orderNoTag valueTag"
                "date orderNo value"
                "realizationTag statusTag value"
                "realization status status";

            .date,
            .realization {
                font-size: 0.9rem;
            }

            .orderNo {
                font-size: 0.9rem;
            }

            .value {
                font-size: 0.9rem;

                border-bottom: 1px solid var(--border-theme-light);
            }

            .status {
                font-size: 0.9rem;
            }
        }

        .productEntry {
            display: grid;
            grid-template-columns: minmax(50px, 15%) minmax(20px, 20%) minmax(50px, 25%) minmax(18px, 10%) minmax(28px, 15%) minmax(60px, 10%);
            grid-template-rows: 18px 18px 18px 18px 18px;
            grid-row-gap: 4px;
            grid-template-areas:
                "header header header header header header"
                "pic name name qty price price"
                "pic name name qty price price"
                "pic name name qty price price"
                "pic baseUnit unitPrice qty price price";

            padding-left: 5px;
            padding-right: 5px;

            .pic_holder {

                .pic {
                    object-fit: contain;
                    width: 100%;
                    height: auto;
                    min-width: 50px;
                    max-height: 70px;
                }

            }

            .name {
                font-size: 0.7rem;
                line-height: 20px;

                .category {
                    display: none;
                    font-size: 0.6rem;
                    line-height: 20px;
                }

            }

            .add,
            .subtracts {
                line-height: 40px;
            }

            .remove {
                line-height: 20px;
                text-align: center;
                object-fit: contain;
            }

            .qty {
                font-size: 0.8rem;
                line-height: 78px;
            }

            .price {
                font-size: 0.8rem;
                line-height: 78px;
            }

            .unitPrice {
                line-height: 18px;
                text-align: right;

                &::after {
                    content: "";
                }
            }

            .baseUnit {
                line-height: 18px;
            }

            .actionButtonAdd,
            .actionButtonsubtracts {
                width: 35px;
                height: 35px;
            }

            .header {
                grid-template-columns: minmax(50px, 15%) minmax(20px, 20%) minmax(50px, 25%) minmax(18px, 10%) minmax(28px, 15%) minmax(60px, 10%);
                grid-template-areas: "hpic hname hname hactions hprice hprice";

                .basePrice {
                    display: none;
                    visibility: hidden;
                }


                .hpic,
                .hname,
                .basePrice,
                .hactions,
                .hprice {
                    font-size: 0.7rem;
                }

            }
        }


    }

}