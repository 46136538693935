@import '../../../../../styles/mixins.scss';

.Inpost {

    margin: 10px 0px 10px 0px;
    background-color: var(--primary-l1);
    border-radius: var(--radius);

    .hd_title {
        font-weight: bold;
        font-size: 1.2rem;
        border-bottom: 1px solid var(--border-theme);
        border-radius: var(--radius) var(--radius) 0 0;
        padding: 10px 10px;
        line-height: 1.2rem;
        user-select: none;
        height: 41px;
    }

    .ft_footer {
        text-align: center;
        border-top: 1px solid var(--border-theme);
        border-radius: 0 0 var(--radius) var(--radius);
        padding: 10px 10px;
        line-height: 1.2rem;
        height: 41px;
    }


    .info_holder {
        display: grid;
        grid-template-columns: 110px 110px 110px auto;
        grid-template-rows: 1.2rem 1.2rem 1rem 1.2rem 1rem 1.2rem 1rem 1.2rem 1rem 60px;
        grid-template-areas:
            "title title title ."
            "tagName tagName tagName image"
            "name name name image"

            "tagStreet tagStreet tagStreet image"
            "street street street image"
            "tagCity tagCity tagCity image"

            "city city city image"
            "tagOpenHours tagOpenHours tagOpenHours image"
            "openHours openHours openHours image"

            "actionBar actionBar actionBar actionBar";
      
        column-gap: 10px;
        row-gap: 10px;
        padding: 5px 15px 15px 15px;
        margin: 10px 0px 10px 0px;
        
        .title {
            grid-area: title;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
            font-weight: bold;
        }

        .tagName {
            grid-area: tagName;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 7px;
            width: 100%;
        }

        .tagStreet {
            grid-area: tagStreet;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 7px;
            width: 100%;
        }

        .tagBulding {
            grid-area: tagBulding;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 7px;
            width: 100%;
        }

        .tagZipCode {
            grid-area: tagZipCode;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 7px;
            width: 100%;
        }

        .tagCity {
            grid-area: tagCity;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 7px;
            width: 100%;
        }

        .tagOpenHours {
            grid-area: tagOpenHours;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 7px;
            width: 100%;
        }

        
        .name {
            grid-area: name;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
        }

        .street {
            grid-area: street;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
        }

        .bulding {
            grid-area: bulding;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
        }

        .zipCode {
            grid-area: zipCode;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
        }

        .city {
            grid-area: city;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
        }

        .openHours {
            grid-area: openHours;
            text-align: left;
            padding-left: 7px;
            height: 1rem;
        }

        .image {
            grid-area: image;
            width: auto;
            height: 100%;
            border: 1px solid var(--border-theme);
            border-radius: var(--radius);
        
        }

        .paymentButton {
            grid-area: actionBar;
            width: 100%;
            height: 100%;
            line-height: 60px;


            .payButton {
                padding: 10px;
                margin: auto;
                width: 100%;
                height: 40px;
            }
        }
        
    }
    .leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
        display: none;
    }

    .map_holder {
        background-color: var(--primary-l1);
        border-radius: var(--radius);
        margin: 10px 0px 10px 0px;
        padding: 10px 10px;
        z-index: 0;

        .easypack-widget {
            border-radius: var(--radius);

            .inpost-search__list {
                top: 50px;
            }

            .search-widget {
                padding: 7px;
                border-radius: var(--radius) var(--radius) 0 0;

                .btn {
                    width: 40px;
                    height: 40px;
                    background-size: 40%;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: var(--radius);

                    &:hover {
                        background-size: 40%;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: var(--radius);
                    }

                }

                #searchLoader {
                    height: 40px;
                }

                .search-group {
                    height: 40px;
                    padding: 0px;

                    .search-input {
                        height: 40px;
                    }
                }

            }

            .map-list-row .map-list-flex {
                border-radius: 0 0 var(--radius) var(--radius);
            }

            .map-list-row .map-list-flex .map-widget{
                border-radius: 0 0 0 var(--radius);
            }
            
            .map-list-row .map-list-flex .list-widget {
                border-radius: 0 0 var(--radius) 0;
            }


        }
    }



    @include mobile {

        .map_holder {
            width: 100vw;
            height: auto;
            margin: 0px;
            padding: 0px;

            .easypack-widget {
                border-radius: 0;
    
                .search-widget {
                    padding: 7px;
                    border-radius: 0;
    
                    .btn {
                        border-radius: 0;
                        &:hover {
                            border-radius: 0;
                        }
    
                    }
    
                }
    
                .map-list-row .map-list-flex {
                    border-radius: 0;
                }
    
                .map-list-row .map-list-flex .map-widget{
                    border-radius: 0;
                }
                
                .map-list-row .map-list-flex .list-widget {
                    border-radius: 0;
                }
    
    
            }
        }

        .info_holder {
            display: grid;
            grid-template-columns: 110px 110px 110px;
            grid-template-rows: 1.2rem 1.2rem 1rem 1.2rem 
                                1rem 1.2rem 1rem 1.2rem 
                                1rem 10rem;
            grid-template-areas:
                "title title title" 
                "tagName tagName tagName"
                "name name name"
                "tagStreet tagStreet tagBulding"
                
                "street street bulding"
                "tagCity tagCity tagCity"
                "city city city"
                "tagOpenHours tagOpenHours tagOpenHours"
                
                "openHours openHours openHours"
                "image image image";
          
            column-gap: 10px;
            row-gap: 10px;
            padding: 5px 15px 5px 15px;
            margin: 10px 0px 10px 0px;

        }
    }


}