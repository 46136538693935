.MilestoneBoxes {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    //background-color: #232427;


    .container {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 40px 0px;
        position: relative;


        .card {
            position: relative;
            min-width: 320px;
            height: 440px;
            box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
                inset -5px -5px 15px rgba(255, 255, 255, 0.1),
                5px 5px 15px rgba(0, 0, 0, 0.3),
                -5px -5px 15px rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            margin: 20px;

            $colors: red, orange, rgb(56, 116, 44), green, blue, purple;
            $repeat: 10; // How often you want the pattern to repeat.
            // Warning: a higher number outputs more CSS.

            @for $i from 1 through $repeat {
                &:nth-child(#{$i}) .box .content a {
                    //background: lighten(nth($colors, random(length($colors))+ 1) , 10%);
                    background: $colors($i);

                    //background: color.adjust(nth($colors, random(length($colors))+ 1), $lightness: 10%);
                }
            }

            .box {
                position: absolute;
                top: 20px;
                left: 20px;
                right: 20px;
                bottom: 20px;
                background: #2a2b2f;
                border: 2px solid #1e1f23;
                border-radius: 15px;
                box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
                transition: 0.5s;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    transform: translateY(-50px);
                    box-shadow: 0 40px 70px rgba(0, 0, 0, 0.5);
                }

                .content {
                    display: block;
                    padding: 20px;
                    text-align: center;
                    position: relative;
                    height: 100%;
                    width: 100%;


                    h2 {
                        position: absolute;
                        top: 60px;
                        right: 5px;
                        font-size: 8em;
                        color: rgba(255, 255, 255, 0.05);
                        margin: unset;
                        user-select: none;
                    }

                    h3 {
                        font-size: 1.2rem;
                        line-height: 1.1rem;
                        height: 2.4rem;
                        color: rgba(255, 255, 255, 0.5);
                        z-index: 1;
                        transition: 0.5s;
                        margin-bottom: 10px;
                        margin-top: 120px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 16px;
                        color: rgba(255, 255, 255, 0.5);
                        z-index: 1;
                        transition: 0.5s;
                    }

                    a {
                        position: relative;
                        display: inline-block;
                        padding: 8px 20px;
                        background-color: #000;
                        margin-top: 15px;
                        border-radius: 20px;
                        text-decoration: none;
                        font-weight: 400;
                        color: #fff;
                        box-shadow: 0 10px 20ox rgba(0, 0, 0, 0.2);


                    }

                    .status {
                        position: absolute;
                        bottom: 0px;
                        left: 10%;
                        display: inline-block;
                        margin-bottom: 15px;
                        min-height: 40px;
                        width: 80%;
                        padding: 0px 20px;
                        border-radius: 20px;
                        text-decoration: none;
                        font-weight: bold;
                        line-height: 40px;
                        box-shadow: 0 10px 20ox rgba(0, 0, 0, 0.2);
                    }

                    .not_started {
                        background-color: rgba(160, 167, 166, 1);

                        &:hover{
                            background-color: rgb(179, 187, 186);

                        }
                       
                    }

                    .in_work {
                      
                        background-color: rgb(0, 38, 255);

                        &:hover{
                            background-color: rgb(50, 81, 255);

                        }
                    }

                    .done {
                     
                        background-color: rgb(11, 151, 86);

                        
                        &:hover{
                            background-color: rgb(0, 167, 89);

                        }
                    }

                }



            }


        }



    }





}