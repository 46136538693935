.CookieDisclaimerCmp {
    display: none;
    position: fixed;
    z-index: 99999;
    min-height: 35px;
    width: 400px;
    background: rgb(43, 54, 67) none repeat scroll 0% 0%;
    border-style: solid;
    border-width: 5px 1px 1px;
    //border-image: none 100% / 1 / 0 stretch;
    border-color: rgb(160, 178, 192);
    text-align: center;
    right: 10px;
    color: rgb(119, 119, 119);
    bottom: 10px;
    box-shadow: black 0px 8px 6px -6px;
    padding: 10px;
    margin: 0 10px 15px 10px;


    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .holder {

        .head {
            font-weight: bold;
            width: 100%;
            font-size: 0.9rem;
        }

        .inside {
            width: 100%;
            font-size: 0.9rem;
        }

        .link {
            color: rgb(160, 178, 192);
            font-size: 0.8rem;

            &:hover {
                text-decoration: underline;
            }
        }

        .space {
            height: 10px;
            display: block;
        }

        .button {
            position: relative;
            background: rgb(160, 178, 192) none repeat scroll 0% 0%;
            color: rgb(255, 255, 255);
            padding: 5px 15px;
            text-decoration: none;
            font-size: 0.8rem;
            font-weight: normal;
            border: 0px solid rgb(43, 54, 67);
            border-radius: 0px;
            text-align: center;
            cursor: pointer;
            margin-top: 10px;

            &:hover{
                text-decoration: underline;
            }

        }

    }

}