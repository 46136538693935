.FilterList {
    position: relative;

    width: 100%;
    padding: 0px 10px;

    font-weight: bold;
    border-radius: var(--radius);

    text-align: right;

    .clear {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 100%;

      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      z-index: 999;
      user-select: none;

      .text {
          width: 100%;
          height: 100%;
          text-align: center;
          line-height: 2rem;
          color: var(--border-theme);

      }

    }

    input[type="search"] {
        height: 2rem;
        outline: solid;
        width: 100%;
        background: none;
        padding: 0 1.6rem;
        z-index: 1;
        position: relative;
        border-radius: var(--radius);
        outline-color: var(--border-theme-light);
    }

      .filterNumber{
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;

        font-size: 1rem;
        font-weight: bold;

        .text {
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 2rem;

        }

      }
      

}