.BeatingHeart {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0px auto 0px auto;
    padding: 15px 0px 0px 0px;
    overflow: hidden;
    border-radius: 0px 0px var(--radius) var(--radius);
    //background-color: var(--primary-light);

    .heart {
        animation: heart-beat 3s ease 0s infinite normal forwards;
        width: 250px;
        height: 220px;
        clip-path: path('M213.1,6.7c-32.4-14.4-73.7,0-88.1,30.6C110.6,4.9,67.5-9.5,36.9,6.7C2.8,22.9-13.4,62.4,13.5,110.9 C33.3,145.1,67.5,170.3,125,217c59.3-46.7,93.5-71.9,111.5-106.1C263.4,64.2,247.2,22.9,213.1,6.7z');
        object-fit: cover;
        margin: 0px auto 0px auto;
    }

    .shadow {
        position: absolute;
        z-index: -1;
        background: rgba(0, 0, 0, .5);
        width: 250px;
        height: 220px;
        left: 5px;
        top: 5px;  
        clip-path: path('M213.1,6.7c-32.4-14.4-73.7,0-88.1,30.6C110.6,4.9,67.5-9.5,36.9,6.7C2.8,22.9-13.4,62.4,13.5,110.9 C33.3,145.1,67.5,170.3,125,217c59.3-46.7,93.5-71.9,111.5-106.1C263.4,64.2,247.2,22.9,213.1,6.7z');
     
        margin: 0px auto 0px auto;
      }

    

    @keyframes heart-beat {
        0% {
            animation-timing-function: ease-out;
            transform: scale(1);
            transform-origin: center center;
        }
    
        10% {
            animation-timing-function: ease-in;
            transform: scale(0.91);
        }
    
        17% {
            animation-timing-function: ease-out;
            transform: scale(0.98);
        }
    
        33% {
            animation-timing-function: ease-in;
            transform: scale(0.87);
        }
    
        45% {
            animation-timing-function: ease-out;
            transform: scale(1);
        }
    }

}