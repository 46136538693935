@import '../../../styles/mixins.scss';

.PDPPage {
  .product {
    background-color: var(--primary-l2);
    color: var(--primary-text);
    border: 2px inset var(--border-theme);
    border-radius: var(--radius);
    margin: 10px 0px;

   
    .title {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: 1.5rem;
      grid-template-areas: "texts goback";

      line-height: 2rem;
      font-weight: bold;
      padding: 0px 10px 0px 15px;
      width: 100%;
      border-bottom: 1px solid var(--border-theme);
      border-radius: var(--radius) var(--radius) 0 0;
      background-color: var(--primary-l1);

      .text {
        grid-area: texts;
        margin-right: 10px;
        line-height: 1.5rem;
        align-self: center;
      }

      
      .goBack{
        grid-area: goback;
        justify-self: right;
        align-self: center;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        width: 100px;
        //background-color: var(--secondary-light);
        border-radius: var(--radius-smaller);
        line-height: 1.3rem;
        height: 1.3rem;
        background-image: linear-gradient(to right, var(--secondary-light) 0%, var(--secondary) 100%);
        opacity: 0.8;

        &:hover{
          opacity: 1;
          background-image: unset;
          background-color: var(--secondary-light);
        }
  
      }

    }



    .footers {
      width: 100%;
      height: 2rem;
      font-size: 0.9rem;
      line-height: 2rem;
      background-color: var(--primary-l1);
      color: var(--primary-text);
      border-top: 1px solid var(--border-theme);
      border-radius: 0 0 var(--radius) var(--radius);
      text-align: right;
      padding-right: 8px;

    }

    .content {
      display: grid;
      grid-template-columns: 64px auto auto auto 100px;
      grid-template-rows: auto 2.8rem auto 1.75rem auto auto auto 2.0rem 40px;
      grid-template-areas:
        "brandLogo productImage tagName tagName tagName"
        "brandLogo productImage name name name"
        "brandLogo productImage tagModel tagModel tagModel"
        "galery productImage model model model"
        "galery productImage tagVariants tagVariants tagVariants"
        "galery productImage variant variant variant"
        "galery productImage variant variant variant"
        "galery productImage . tagPrice price"
        "galery productImage actions actions actions";

      width: 100%;
      height: 100%;
      padding: 10px 10px 10px 10px;
      column-gap: 10px;
      row-gap: 10px;

      .brandLogo {
        grid-area: brandLogo;
        width: 100%;
        height: auto;
        //border-radius: var(--radius);
        //border: 1px inset var(--border-theme-light);
        padding: 2px;
        object-fit: contain;
      }

      .productImage {
        grid-area: productImage;
        position: relative; /* added */
        width: 100%;
        height: 100%;
        padding: 5px 15px;
        border-radius: var(--radius);
        border: 1px solid var(--border-theme);
        background-color: white;
        text-align: center;
     
        .image { 
          display: flex;  
          width: 380px;
          height: 380px;
          line-height: 380px;
          align-items: center;
          /* Vertical center alignment */
          justify-content: center;
          /* Horizontal center alignment */
          margin: auto;
          object-fit: contain;
          animation: opacity 0.8s;
        }

        .promo {
          position: absolute; /* added */
          float: right;
          transform: translate(20%, 0px);
          width: 72px;
          height: 32px;
          top: 5px;
          right: 14px;
          line-height: 32px;
          font-size: 0.9rem;
          font-weight: bold;
          text-align: center;
          background-color: red;
          border-radius: var(--radius-smaller) 0 0 var(--radius-smaller);
          color: yellow;
          z-index: 20;
        } 

      }

      .tagName {
        grid-area: tagName;
        width: 100%;
        height: 100%;
        text-align: left;
        color: var(--primary-light-text);
      }

      .name {
        grid-area: name;
        width: 100%;
        height: 100%;
        border-radius: var(--radius);
        border: 1px inset var(--border-theme-light);
        background-color: var(--primary-d1);
        padding-left: 10px;

        .text {
          display: flex;
          align-items: center;
          /* Vertical center alignment */
          //justify-content: center; /* Horizontal center alignment */
          height: 100%;

          padding: 6px;
          font-size: 0.8rem;
          line-height: 0.8rem;
          word-wrap: break-word;
          color: var(--primary-text);
        }

      }

      .model {
        grid-area: model;
        width: 100%;
        height: 100%;
        border-radius: var(--radius);
        border: 1px inset var(--border-theme-light);
        padding-left: 10px;
        color: var(--primary-text);
        background-color: var(--primary-d1);

        .text {
          display: flex;
          align-items: center;
          /* Vertical center alignment */
          //justify-content: center; /* Horizontal center alignment */
          font-size: 0.9rem;
          line-height: 0.9rem;
          height: 100%;
          color: var(--primary-text);
        }

      }

      .variant {
        grid-area: variant;
        width: 100%;
        height: 100%;
        color: var(--primary-text);
        padding-left: 10px;

        .variantColour {
          border-radius: var(--radius);
          border: 1px inset var(--border-theme-light);
          background-color: var(--primary-d1);
          height: 3rem;
          line-height: 3rem;
          padding-left: 5px;
          margin-bottom: 10px;
        }

        .variantSize {
          border-radius: var(--radius);
          border: 1px inset var(--border-theme-light);
          background-color: var(--primary-d1);
          height: 3rem;
          line-height: 3rem;
          padding-left: 5px;
          margin-bottom: 10px;
        }

        .variantPackage {
          border-radius: var(--radius);
          border: 1px inset var(--border-theme-light);
          background-color: var(--primary-d1);
          height: 3rem;
          line-height: 3rem;
          padding-left: 5px;
          margin-bottom: 10px;

        }

      }

      .tagModel {
        grid-area: tagModel;
        width: 100%;
        height: 100%;
        text-align: left;
        color: var(--primary-light-text);
        user-select: none;
      }

      .tagVariants {
        grid-area: tagVariants;
        width: 100%;
        height: 100%;
        text-align: left;
        color: var(--primary-light-text);
        user-select: none;
      }

      .tagPrice {
        grid-area: tagPrice;
        width: 100%;
        height: 100%;
        text-align: left;
        color: var(--primary-light-text);
        line-height: 1.5rem;
        padding: 0.25rem;
        user-select: none;
      }

      .price {
        grid-area: price;
        width: 100%;
        height: 100%;
        text-align: right;
        font-size: 1.0rem;
        line-height: 1.5rem;
        padding: 0.25rem;
        color: var(--primary-text);
        border-radius: var(--radius);
        border: 1px inset var(--border-theme-light);
        background-color: var(--primary-d1);
      }

      .promo{
        color: rgb(77, 228, 77);
      }

      .promoValue{
        grid-area: promoValue;
        width: 100%;
        height: 100%;
        text-align: right;
        font-size: 1.0rem;
        line-height: 1.5rem;
        padding: 0.25rem;
        color: var(--primary-text);
        border-radius: var(--radius);
        border: 1px inset var(--border-theme-light);
        background-color: var(--primary-d1);
        max-width: 120px;
      }

      .actions {
        grid-area: actions;
        width: 100%;
        height: 100%;

        .addTocart {
          width: 100%;
          height: 100%;
        }

      }

      .galery {
        grid-area: galery;
        border-radius: var(--radius);
        border: 1px inset var(--border-theme);
        margin-bottom: 10px;
        text-align: center;
        padding: 2px;
        background-color: white;
        width: 100%;
        height: 100%;

        .galeryImg {
          cursor: pointer;
          width: 46px;
          height: 46px;
          padding: 2px;
          margin: 2px 0px 2px 0px;
          border-bottom: 2px inset var(--border-theme-light);
          border-radius: var(--radius);

          &:hover {
            //box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.50);
            border-bottom: 2px inset var(--border-theme);
          }

        }
      }


    }

  }


  @include mobile {
    .product {
      .content {
        grid-template-columns: 40% 20% auto;
        grid-template-rows: 1rem 3rem 1rem 1.3rem auto 1rem auto 2.2rem 2.2rem 35px;
        row-gap: 5px;
        grid-template-areas:
          "productImage tagName tagName"
          "productImage name name"
          "productImage tagModel tagModel"
          "productImage model model"

          "galery galery galery"
          "tagVariants tagVariants tagVariants"
          "variant variant variant"
          "brandLogo . ."

          "brandLogo tagPrice price"
          "actions actions actions";

        .tagName {
          line-height: 1rem;
          font-size: 0.8rem;
        }

        .name {
          line-height: 0.9rem;
          font-size: 0.8rem;
          height: 100%;
          padding: 0px 5px 0px 5px;
        }

        .tagModel {
          line-height: 1rem;
          font-size: 0.8rem;
        }

        .model {
          line-height: 1.3rem;
          font-size: 0.9rem;
          height: 100%;
          padding: 0px 5px 0px 5px;
        }

        .brandLogo {
          width: auto;
          max-height: 100%;
        }

        .tagVariants {
          line-height: 1rem;
          font-size: 0.8rem;
        }

        .variant {
          padding: 0px;

          .variantColour,
          .variantSize,
          .variantPackage {
            height: 40px;
            line-height: 1.3rem;
            font-size: 0.9rem;
          }
        }

        .productImage {

          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
            border-radius: var(--radius-smaller);
          }

          .promo {
            width: 38px;
            height: 22px;
            top: 5px;
            right: 8px;
            line-height: 22px;
            font-weight: bold;
            font-size: 0.8rem;
          } 

        }

        .galery {
          height: 40px;
          padding: 0px;
          text-align: left;
          min-width: max-content;

          .galeryImg {
            height: 100%;
            width: auto;
            padding: 2px;
            margin: 0px 6px 0px 6px;
            border-radius: 0px;
            border: 0px;

            &:hover {
              border: 0px;
            }
          }

        }

        .tagPrice {
          line-height: 1rem;
          font-size: 0.9rem;
        }

        .price {
          display: flex;
          align-items: center;
          text-align: right;
          justify-content: right;
          height: 100%;
          line-height: 1rem;
          font-size: 0.9rem;
          padding: 0px 5px 0px 5px;
        }

      }
    }

  }

}