.BubbleText {
    position: relative;
    display: block;
    width: 100%;
    //text-align: center;
    margin: 0px auto 0px auto;
    //padding: 15px 0px 0px 0px;
    border-radius: 0px 0px var(--radius) var(--radius);
    //background-color: var(--primary-light);

    .bubble {
        display: flex;
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        min-width: 40px;
        max-width: 220px;
        min-height: 40px;
        margin: 20px;
        position: relative;
        align-items: center;
        justify-content: center;
        text-align: center;
        user-select: none;
    }

    .txt {
        color: var(--always-black);
    }

    .bubble:before,
    .bubble:after {
        content: "";
        background-color: #fff;
        border-radius: 50%;
        display: block;
        position: absolute;
        z-index: -1;
    }

    .bubble:before {
        width: 44px;
        height: 44px;
        top: -12px;
        left: 28px;
        box-shadow: -50px 30px 0 -12px #fff;
    }

    .bubble:after {
        bottom: -10px;
        right: 26px;
        width: 30px;
        height: 30px;
        box-shadow: 40px -34px 0 0 #fff,
            -28px -6px 0 -2px #fff,
            -24px 17px 0 -6px #fff,
            -5px 25px 0 -10px #fff;

    }
}