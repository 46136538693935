.LogoShowCaseCmp {
    margin-top: 15px;

    .container {
        display: grid;
        grid-template-columns: 20px auto 20px;
        grid-template-rows: 2rem 80px;

        grid-template-areas:
            "title title title"
            "left_arrow logo_container right_arrow";

    }

    .title {
        grid-area: title;
        font-size: 1rem;
        font-weight: bold;
        line-height: 2rem;
    }

    .logo_container {
        grid-area: logo_container;
        text-align: center;
        border-radius: var(--radius);
        background-color: rgba(#d1d1d1, 0.25);
        filter: grayscale(40%);
        padding: 5px;
    }

    
    .logo {
        height: 70px;
        width: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 15px;
    }

    .left_arrow {
        grid-area: left_arrow;
        align-self: center;
    }

    .right_arrow {
        grid-area: right_arrow;
        align-self: center;
    }

    img {
        width: auto;
        height: auto;
    }


}