@import '../../../../../styles/mixins.scss';

.DPD {
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .savedAdr {
        width: 100%;
        height: 100px;
        border-bottom: 1px solid var(--border-theme);
        padding: 5px 0px 5px 0px;
        user-select: none;
        text-align: center;
        margin: 0px;
        
        .action_holder {
            width: 100%;
            margin-bottom: 5px;
            text-align: right;
            border-bottom: 1px solid var(--border-theme);

            .delete {
                cursor: pointer;
                width: 15px;
                height: 15px;
                //FIX background-image: 'url("/images/core/red_bin_48x48.png")';
                background-size: 100% auto;
                background-repeat: no-repeat;
                margin: 2px auto;

                &:hover {
                    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.59);
                }
            }
        }

        .tile {
            user-select: none;
            display: inline-block;
            height: 100%;
            width: 100px;
            text-align: center;
            border: 1px solid var(--border-theme);
            border-radius: var(--radius);
         
            .infoHolder {
                cursor: pointer;

                .text {
                    padding: 2px;
                    font-size: 0.8rem;
                    word-wrap: break-word;
                }
            }

            &:hover {
                box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.59);
            }

            &:not(:last-child){
                margin-right: 15px;
            }

        }

    }

    .valid {
        background-color: var(--valid);
    }

    .invalid {
        background-color: var(--invalid);
    }

    .holder {
        display: grid;
        grid-template-columns: auto 110px 30% auto;
        grid-template-rows: 1.2rem auto 1.2rem auto 1.2rem auto 1.2rem auto 1.2rem 5rem;
        grid-template-areas:
            ". tagStreet tagStreet ."
            ". street street ."
            ". tagZipCode tagCity ."
            ". zipCode city ."
            ". tagEmail tagEmail ."
            ". email email ."
            ". tagPhone tagPhone ."
            ". phone phone ."
            ". tagComment tagComment ."
            ". comment comment .";
        column-gap: 10px;
        row-gap: 10px;
        padding: 5px 15px 15px 15px;
        text-align: left;

        .name {
            grid-area: name;
            text-align: left;
            padding-left: 7px;
        }

        .street {
            grid-area: street;
            text-align: left;
            padding-left: 7px;
        }

        .bulding {
            grid-area: bulding;
            text-align: left;
            padding-left: 7px;
        }

        .zipCode {
            grid-area: zipCode;
            text-align: center;
            padding-left: 7px;
        }

        .city {
            grid-area: city;
            text-align: left;
            padding-left: 7px;
        }

        .email {
            grid-area: email;
            text-align: left;
            padding-left: 7px;
        }

        .phone {
            grid-area: phone;
            text-align: left;
            padding-left: 7px;
        }

        .comment {
            grid-area: comment;
            text-align: left;
            resize: none;
        }

        .tagName {
            grid-area: tagName;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagStreet {
            grid-area: tagStreet;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagBulding {
            grid-area: tagBulding;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagZipCode {
            grid-area: tagZipCode;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagCity {
            grid-area: tagCity;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagEmail {
            grid-area: tagEmail;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagPhone {
            grid-area: tagPhone;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }

        .tagComment {
            grid-area: tagComment;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 5px;
            width: 100%;
        }
    }

    .paymentButton {
        display: block;
        width: 100%;
        line-height: 70px;
        text-align: center;
        padding: 0px 6px;

        .payButton {
            width: 100%;
            max-width: 300px;
            height: 40px;
            margin: 0px auto;
        }

    }

    .message {
        display: block;
        width: 100%;
        height: 2rem;
        line-height: 2rem;
        padding-bottom: 5px;
    }

    @include mobile {

        .holder {
            display: grid;
            grid-template-columns: auto 200px;
            grid-template-rows: 1.2rem auto 1.2rem auto 1.2rem auto 1.2rem auto 1.2rem 5rem;
            grid-template-areas:
                "tagStreet tagStreet"
                "street street"
                "tagZipCode tagCity"
                "zipCode city"
                "tagEmail tagEmail"
                "email email"
                "tagPhone tagPhone"
                "phone phone"
                "tagComment tagComment"
                "comment comment";
            column-gap: 10px;
            row-gap: 10px;
            padding: 5px;
            margin: 0px;
        }


    }

}