.CardRecipesHolder {

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    gap: 30px 5px;

}