.RateUsPage {

    max-width: 460px;

    .label {
        display: block;
        width: 100%;
        margin: 10px 0px;

    }

    .holder_content {
        padding: 15px;
    }

    .image_holder_after_submit{
        border-radius: var(--radius);
        background-color: white;
        color: black;
        text-align: center;
        width: 100%;
        padding: 5px 0px;

        img {
            width: 20%;
            height: auto;
            border-radius: var(--radius);
        }

        .label{
            color: black;
        }
    }

    .image_holder {
        border-radius: var(--radius);
        background-color: white;
        text-align: center;
        width: 100%;
        padding: 5px 0px;


        img {
            cursor: pointer;
            filter: grayscale(1);
            width: 20%;
            height: auto;
            border-radius: var(--radius);

            &:not(:last-child){
                margin-right: 5%;
            }


            &:hover {
                box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.59);
                filter: grayscale(0.6);
            }
        }

        .active {
            filter: unset;
        }


    }

    .comment_holder{
        text-align: center;

        .comment{
            width: 100%;
        }
        
    }

    .action_holder {

        text-align: center;

        .button {
            width: 100%;
            margin: 10px 0px;
            height: 3rem;
        }
    }

}