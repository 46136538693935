
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 360px;

@mixin mobile {
    @media screen and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: #{$desktop-width}) {
        @content;
    }
}

@mixin push--auto {
    margin: {
        left: auto;
        right: auto;
    }
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}