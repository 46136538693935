@import '../../../../../styles/mixins.scss';

/* Footer */

.footer {
    width: 100%;
    height: auto;
    left: 0px;
    bottom: 0px;
    padding: 5px 30px;
    text-align: center;
    background-color: var(--primary);
    background-image: radial-gradient(circle farthest-corner at center, #1b2426 0, #1b2426 50%, #3b4e53 100%);
    position: fixed;
    clear: both;
    text-align: center;
    margin: 0px auto 0px auto;
    min-width: 250px;
    z-index: 2000;
    color: var(--always-white);


    h3 {
        margin-bottom: 5px;
        font-weight: bold;
        color: var(--always-white);
    }

    .row {
        display: flex;
        margin: 0px auto 0px auto;
        width: auto;
        margin-bottom: 3px;
    }

    .col-sm {
        margin-top: 7px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        text-align: left;

        a {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            text-decoration: none;
            // color: lighten(#6c8f91, 30%);
        }

        p, a {
            color: var(--always-white);
        }

        .header {

            .line {
                margin-left: 7px;
                margin-bottom: 7px;
                height: 1.2rem;
                line-height: 1.2rem;


                a {
                    height: 100%;
                    line-height: inherit;
                    margin: auto 0px auto 0px;
                    vertical-align: middle;
                    //border-bottom: 1px solid rgba(0, 0, 0, 0);

                    img {
                        height: 100%;
                        margin: auto 5px auto 0px;
                        vertical-align: middle;
                        filter: grayscale(80%);
                    }

                    p {
                        display: inline;
                        vertical-align: middle;
                    }

                }

                &:hover {

                    //color: var(--primary-text);
                    //border-bottom: 3px solid var(--secondary);
                    img {
                        filter: brightness(110%);
                    }

                    /*a {
                        //border-bottom: 1px solid var(--secondary);
                        //color: var(--secondary-text);

                    }*/
                }



            }
        }

    }

    .copy_write, .sf-footer-version { 
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 0.7rem;
        color: var(--always-grey-l1);
        text-decoration: none;
        height: auto;
        width: 100%;
        text-align: center;
        padding-top: 5px;

    }

   

    @include tablet {
        height: auto;
        text-align: center;


        .col-sm {
            width: 100%;
            flex-basis: unset;
            flex-grow: unset;
            text-align: left;
            margin-bottom: 10px;

            .header {

                .line {
                    margin-bottom: 12px;
                    height: 1.5rem;
                    line-height: 1.5rem;
                }
            }
        }

        .row {
            flex-direction: column;
            font-size: 1.1rem;
            line-height: 1.1rem;

        }

    }

}