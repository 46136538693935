@import '../../../../styles/mixins.scss';

.SingleImageCmp {
    display: block;
    width: 100%;
    height: 200px;
    min-height: 200px;
    text-align: center;
    margin: 0px auto 0px auto;
    padding: 0px;
    overflow: hidden;
    border-radius: 0px 0px var(--radius) var(--radius);
    //background-color: var(--primary-light);

    img {
        height: 100%;
        width: 100%;
        margin: 0px;
        padding: 0px;
        border-radius: 0px 0px var(--radius) var(--radius);
    }

    @include mobile {

        height: auto;
        min-height: 50px;
        margin: 0px;

        img {
            height: auto;
        }

    }

}