.SwitchDarkMode {

    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    cursor: pointer;

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(158, 158, 158);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: var(--radius);

        &:before {
            position: absolute;
            content: "";
            height: 19px;
            width: 19px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: var(--radius);
        }

    }

    input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0px;
        margin: 0px;
        border: none;
        border-radius: 0;

        &:checked+.slider {
            background-color: #2196F3;
        }

        &:focus+.slider {
            box-shadow: 0 0 1px #2196F3;
        }

        &:checked+.slider:before {
            -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
            transform: translateX(24px);
        }

    }

    .sun {
        position: absolute;
        right: 0px;
        top: 6px;
        transition: 0, 3s;
        width: 50%;
        height: 15px;
        z-index: 100;
        opacity: 0.6;
    }

    .moon {
        position: absolute;
        left: 0px;
        top: 6px;
        transition: 0, 3s;

        width: 50%;
        height: 15px;
        z-index: 100;
        opacity: 0.6;
    }



}