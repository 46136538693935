   /* Dropdown container - needed to position the dropdown content */
   //DropdownMenuCmp
   .dropdown_menu,
   .myAccount {
       display: block;
       float: left;
       margin: 0px;
       min-width: max-content;
       padding: 0px 0px;
       margin: 0px 0px 0px 0px;
       height: auto;
       cursor: pointer;
       max-width: 90px;
       user-select: none;

       &:hover {
           //background-color: var(--secondary);
           color: var(--secondary-text);
       }

       p,
       .pline {
           min-width: 40px;
           text-align: center;
           padding: 0px 10px;
           height: 40px;
           line-height: 40px;
           cursor: pointer;
           border-bottom: 4px solid var(--transparent);
           
           &:hover {
            color: var(--secondary-text);
            border-bottom: 4px solid var(--secondary);
           }

       }

       .text {
           display: block;
           float: left;
           height: 40px;
           line-height: 40px;
       }

       .icon {
           display: block;
           float: left;
           height: 40px;
           line-height: 40px;

       }

       img {
           margin: 0px;
           padding: 0px;
           width: 13px;
           height: 13px;
       }

       .dropdown_icon {
           display: block;
           margin: 0px;
           border: none;
           cursor: pointer;
           float: left;
           padding: 8px 5px 8px 0px;
           height: 40px;
           width: auto;
       }

       .dropdown_content {
           display: block;
           position: fixed;
           border-bottom: 4px solid var(--secondary);
           border-radius: 0px 0px var(--radius) var(--radius);
           background-color: var(--secondary-light);
           color: var(--primary-text);
           padding: 0px 0px 0px 0px;
           z-index: 11;
           max-height: 80vh;
           height: auto;
           animation: fadeIn 0.8s;

           .dropdown_elements {
               display: block;
               max-height: 80vh;
               overflow-y: auto;
               overflow-x: hidden;
           }

           a,
           .pline {
               display: block;
               min-width: 160px;
               text-align: left;
               height: 40px;
               line-height: 40px;
               padding: 0px 35px 0px 15px;

               &:hover {
                   background-color: var(--secondary);
                   color: var(--secondary-text);
                   border-radius: 0px;
               }

               &:last-child:not(:first-child) {
                   border-radius: 0px 0px var(--radius-smaller) var(--radius-smaller);
               }

           }

       }

   }