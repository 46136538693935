.OilBottle {
    display: block;

    $color: rgba(47, 0, 255, 1);
    $color005: rgba(47, 0, 255, 0.05);
    $color01: rgba($color, 0.1);
    $color02: rgba($color, 0.2);
    $color03: rgba($color, 0.3);
    $color035: rgba($color, 0.35);
    $color04: rgba($color, 0.4);
    $color05: rgba($color, 0.5);
    $color055: rgba($color, 0.55);
    $color06: rgba($color, 0.6);
    $color07: rgba($color, 0.7);


    :before,
    :after {
        content: '';
        display: block;
        position: absolute;
    }

    .bottle {
        display: inline-block;
        position: relative;
        width: 100px;


        &:after {
            height: 14px;
            width: 100px;
            border-radius: 46px/10px;
            box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.2), 0px 2px 10px -3px rgba(0, 0, 0, 0.1);
            bottom: -7.6px;
            z-index: 0;
        }

        &:before {
            height: 14px;
            width: 100px;
            border-radius: 46px/10px;
            box-shadow: 0px 2px 10px -2px $color, 0px 2px 15px -2px $color;
            bottom: -7.6px;
            z-index: 0;
        }

    }

    .bottle_description {
        line-height: 3.2rem;
        font-size: 1.5rem;
        height: 2rem;
        color: var(--secondary-dark);
    }

    .bottle_drops {
        line-height: 50px;
        font-size: 1.5rem;
        height: 50px;
        font-weight: bold;
        color: var(--secondary-dark);
    }

    .drops {
        line-height: 70px;
        height: 60px;

        background-image: url('../../../assets/images/core/drop-light-blue.png');
        background-size: auto 60px;
        background-position: center center;
        background-repeat: no-repeat;

    }

    .bottle_top {
        height: 30px;
        width: 80px;
        position: relative;
        border: 1px solid $color;
        border-top: none;
        border-bottom: none;
        margin: 0px auto;
        box-shadow: 0px 0px 6px $color04 inset;
        background-color: $color005;

        &:after,
        &:before {
            height: 10px;
            width: 78px;
            border-radius: 46px/10px;
            border: 2px solid $color05;
            border-bottom: 2px solid $color06;
            border-top: 1px solid $color04;
            left: -1px;
            box-shadow: 0px 0px 6px $color06 inset;
        }

        &:after {
            top: -6px;
            box-shadow: 0px 0px 6px $color06 inset, 0px 3px 3px -1px $color03;
        }

        &:before {
            top: 24px;
            box-shadow: 0px 0px 6px $color04 inset;
        }


        .highlight {
            display: block;
            height: 30px;
            width: 20px;
            position: absolute;
            left: -18px;
            top: 5px;
            transform: skew(0deg, 5deg);
            background: linear-gradient(to right, $color01, $color02 4%, $color01 98%, rgba(255, 255, 255, 0));

            &:after {
                height: 30px;
                width: 2px;
                left: -3px;
                top: -1px;
                transform: skew(0deg, 5deg);
                background: $color01;
            }
        }

    }

    .bottle_neck {
        height: 20px;
        width: 62px;
        position: relative;
        background: none;
        border: 1px solid $color02;
        border-top: none;
        border-bottom: none;
        margin: 0px auto;

        &:after,
        &:before {
            height: 8px;
            width: 62px;
            border-radius: 30px/8px;
            border: 1px solid $color05;
            border-top: none;
            left: -1px;
        }

        &:after {
            top: -4px;
            border: 1px solid $color02;
            box-shadow: 0px 5px 3px -2px $color05;
        }

        &:before {
            top: 15px;
        }

        .highlight {
            display: block;
            height: 20px;
            width: 25px;
            position: absolute;
            left: 2px;
            top: 6px;
            transform: skew(0deg, 6deg);
            background: linear-gradient(to right $color01, $color03 4%, $color01 98%, rgba(255, 255, 255, 0));
        }

    }

    .bottle_main {
        height: 150px;
        position: relative;
        width: 98px;
        background: none;
        border: 1px solid $color035;
        border-top: none;
        border-bottom: none;

        &:after,
        &:before {
            height: 12px;
            width: 96px;
            border-radius: 46px/10px;
            border: 2px solid $color05;
            border-bottom: 2px solid $color07;
            border-top: 1px solid $color03;
            left: -1px;
        }

        &:after {
            top: -7px;
            box-shadow: 0px 0px 6px $color06 inset, 0px 3px 3px -1px $color04, 0px 0px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 6px $color005 inset, 0px 0px 3px rgba(0, 0, 0, 0.15) inset;
        }

        &:before {
            top: 143px;
            box-shadow: 0px 0px 6px $color06 inset, 0px 0px 3px rgba(0, 0, 0, 0.15) inset;
        }


        .highlight {
            display: block;
            height: 150px;
            width: 25px;
            position: absolute;
            left: 08px;
            top: 6px;
            transform: skew(0deg, 6deg);
            background: linear-gradient(to right, $color01, $color01 4%, $color01 98%, rgba(255, 255, 255, 0));
            z-index: 2;

            &:after {
                height: 150px;
                width: 4px;
                left: -6px;
                top: 0px;
                transform: skew(0deg, 3deg);
                background: linear-gradient(to bottom, $color03, rgba(255, 255, 255, 0) 80%);
            }

        }


    }

    .bottle_mouth {
        height: 52px;
        width: 44px;
        margin: 0px auto;
        position: relative;
        background: none;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-top: none;
        border-bottom: none;
        background: linear-gradient(to right $color03, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0) 95%, $color03);
        //box-shadow: 0px 0px 6px $color03;

        &:after {
            height: 6px;
            width: 44px;
            border-radius: 25px/6px;
            border: 1px solid $color01;
            left: -1px;
            top: -4px;
            z-index: 1;
            box-shadow: 0px 0px 3px $color02;
        }

    }

    .bottle_inner {
        height: 149px;
        width: 92%;
        margin: 0px auto;
        border-radius: 10px 10px 20px 20px/10px;
        border: 1px solid rgba(0, 0, 0, 0.01);
        border-top: 1px solid $color03;
        border-bottom-width: 0.5px;
        position: relative;
        box-shadow: 0px 0px 2px $color05 inset, 0px 0px 8px $color06 inset, 0px 0px 5px $color05;
        position: relative;
        top: 1px;

        &:before {
            border-radius: 40px/4px;
            top: 140px;
            width: 90%;
            left: 3px;
            height: 9px;
        }
    }

    .water {
        background: linear-gradient(-85deg, $color035, $color055 70%, $color04);
        width: 98%;
        height: 80px;
        position: absolute;
        left: 1px;
        bottom: 1px;
        border-radius: 50px 50px 20px 20px/8px 8px 10px 10px;
        box-shadow: 0px 0px 6px $color05 inset, 0px 0.2px 3px -1px rgba(0, 0, 0, 0.3) inset, 0px 2px 6px -1px rgba(0, 0, 0, 0.1) inset, 0px 2px 5px $color03, 0px 1px 3px $color02;

        &:after {
            height: 10px;
            width: 88px;
            left: -1px;
            top: 0px;
            background: linear-gradient(to right, $color04, $color005 50%, $color01 80%, $color03);
            border-radius: 50px/8px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom-color: $color04;
            box-shadow: 0px 1px 2px -1px rgba(0, 204, 255, 0.35);
        }

        &:before {
            height: 10px;
            width: 82px;
            left: 2px;
            bottom: -1px;
            background: linear-gradient(to right, $color02, $color01 50%, $color02 80%, $color03);
            border-radius: 50px/10px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: none;
            box-shadow: 0px -2px 3px -1px rgba(0, 204, 255, 0.25);
        }
    }

    $colors: ('red': red, 'light blue': rgb(9, 108, 238), 'orange': orange, 'green': green, 'blue': blue, 'purple': purple, 'navy': navy, 'teal': teal, 'fuchsia': fuchsia, 'silver': silver, 'maroon': maroon, 'aqua': aqua, 'yellow': yellow);

    @each $i,
    $color in $colors {

        $color01b: rgba($color, 0.1);
        $color02b: rgba($color, 0.2);
        $color025b: rgba($color, 0.25);
        $color03b: rgba($color, 0.3);
        $color035b: rgba($color, 0.35);
        $color04b: rgba($color, 0.4);
        $color05b: rgba($color, 0.5);
        $color06b: rgba($color, 0.6);

        .#{$i} {

            .water {
                background: linear-gradient(-85deg, $color04b, $color06b 70%, $color04b);
                box-shadow: 0px 0px 6px $color05b inset, 0px 0.2px 3px -1px rgba(0, 0, 0, 0.3) inset, 0px 2px 6px -1px rgba(0, 0, 0, 0.1) inset, 0px 2px 5px $color03b, 0px 1px 3px $color02b;
                height: 100px;

                &:after {
                    background: linear-gradient(to right, $color04b, $color05b 50%, $color01b 80%, $color03b);
                    box-shadow: 0px 1px 2px -1px $color035b;
                }

                &:before {
                    background: linear-gradient(to right, $color02b, $color01b 50%, $color02b 80%, $color03b);
                    box-shadow: 0px -2px 3px -1px $color025b;
                }

            }

            &:before {
                box-shadow: 0px 2px 10px -2px $color03b, 0px 2px 15px -2px $color04b;
            }

        }
    }

}