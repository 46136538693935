.OilsPage {
    width: 100%;
    max-width: 1200px;  


    .FilterList{

        margin-top: 30px;

    }
}