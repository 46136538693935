@import '../../../styles/mixins.scss';

.CardText {
    position: relative;
    margin: 0px;
    background-color: var(--primary-d4);
    padding: 15px;
    border-radius: var(--radius);

    font-weight: normal;

}