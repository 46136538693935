@import '../../../../styles/mixins.scss';

.CartCoupons {


    border: 2px solid var(--border-theme);
    border-radius: var(--radius);
    background-color: var(--primary-l1);
    padding: 15px 15px 20px 15px;

    .title {
        font-weight: bold;
        line-height: 1rem;
        margin-bottom: 12px;
        text-align: left;
        min-width: 300px;
        width: 100%;

    }

    .couponTitle {
        font-weight: bold;
        line-height: 1rem;
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: left;
        width: 100%;
    }

    .inputCode {
        text-align: center;
        width: 100%;
    }

    .button {
        text-align: center;
        margin-top: 10px;
        width: 100%;
    }

    .coupon {
        background-color: var(--primary-l2);
        border-radius: var(--radius);
        border: 1px inset var(--border-theme);
        margin-top: 10px;

        .head {
            background-color: var(--primary-d1);
            border-radius: var(--radius) var(--radius) 0 0;
            border-bottom: 1px inset var(--border-theme);
            color: var(--primary-light-text);
            text-align: center;
            width: 100%;
            padding: 3px;
            font-size: 0.9rem;
            line-height: 0.9rem;
        }

        .couponHolder {
            display: grid;
            grid-template-columns: auto 20px;
            grid-template-rows: 5px 20px 20px;
            grid-template-areas: "header header"
                "tag2 remove"
                "tag1 remove";

            padding: 7px;

            .id {
                grid-area: tag1;
                width: 100%;
                line-height: 20px;
                font-size: 0.9rem;
                text-align: left;
            }

            .name {
                grid-area: tag2;
                width: 100%;
                line-height: 20px;
                text-align: left;
            }

            .remove {
                grid-area: remove;
                width: 100%;
                line-height: 40px;
                text-align: center;
                cursor: pointer;

                .img {
                    width: 100%;
                    height: auto;
                    line-height: 40px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

    }

    @include mobile {
        width: 100%;

    }

}