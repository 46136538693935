.CheckoutPage {
    display: grid;

    .payHolder {
        grid-area: purchaseBtn;
        justify-self: center;
        padding: 5px;

        .payBtn {
            min-width: 200px;
            height: 100%;
        }
    }

    .changeHolder {
        grid-area: changeBtn;
        justify-self: center;
        padding: 5px;

        .changeBtn {
            min-width: 200px;
            height: 100%;
        }
    }

}