.EventEntries {

    visibility: visible;
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-bottom: 150px;

    .blog-entry {
        display: flex;
        gap: 20px;
        align-items: flex-start;
        background-color: var(--secondary-light);
        border-radius: var(--radius);
        padding: 20px 10px;

        &:first-child {
            margin-top: 20px;
        }
    }

    .blog-img-container {
        width: 50%;
        max-width: 150px;
        text-align: center;
        padding: 10px;
        height: 150px;
        
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .blog-img {
        max-width: 100%;
        display: inline-block;
      }

      .blog-large-img {
        padding: 40px;
        max-width: 100%;
        display: inline-block;
        margin: 0px auto;
        text-align: center;
      }
    
    .blog-content {
        width: 100%;
    }

    .blog-header {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .blog-date {
        font-size: 14px;
        margin-bottom: 10px;
        color: var(--secondary-text);
    }

    .blog-text {
        font-size: 16px;
        line-height: 1.5;
    }

}