.logout {

    text-align: center;
    padding-top: 25px;

    .holder {
        border-radius: var(--radius);
        background-color: var(--primary-l1);
        width: 100%;
        max-width: 350px;
        padding: 18px 8px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        h1 {
            width: 100%;
            padding: 8px;
            text-align: center;
            font-size: 1.3rem;


        }

        .image{
            margin: 10px;
            width: auto;
            height: 250px;
        }


        .text {
            width: 100%;
            padding: 8px;
            text-align: center;
            font-size: 1.3rem;

        }
    }


}