@import '../../../styles/mixins.scss';


.CardPerson {
    position: relative;
    width: 280px;
    text-align: center;
    margin: 0px 0px 0px 0px;

    overflow: hidden;
    border-radius: 0px 0px var(--radius) var(--radius);
    //background-color: var(--primary-light);
    height: 430px;

    @include mobile {
        width: 98%;
    }

    .card {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 10px;
        width: 90%;
        height: 400px;
        border-radius: 10px;
        box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.3);
        background: #151515;
        overflow: hidden;
        background: linear-gradient(180deg, #000 0%, #272727 180%);

        transform-origin: 0px;
        transform-origin: bottom;

        &:hover {

            background: linear-gradient(180deg, #000 0%, #5a0b0b 180%);

            .avatar-holder {

                filter: saturate(0);// contrast(1.3);

                img {
                    transform: scale(1.20);
                }

            }
        }

        .ds-top {
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100px;
            background: rgb(109, 226, 0);
            animation: dsTop 1.5s;
        }

        .avatar-holder {
            position: absolute;
            margin: auto;
            top: 20px;
            right: 0;
            left: 0;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            box-shadow: 0 0 0 5px #151515,
                inset 0 0 0 5px #000000,
                inset 0 0 0 5px #000000,
                inset 0 0 0 5px #000000,
                inset 0 0 0 5px #000000, ;
            background: white;
            overflow: hidden;
            animation: mvTop 1.5s;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 50% 35%;
                transition: transform 5s, filter 3.5s ease-in;
            }
        }

        .name {
            position: absolute;
            margin: auto;
            top: 30px;
            right: 0;
            bottom: 0;
            left: 0;
            width: inherit;
            height: 40px;
            text-align: center;
            animation: fadeIn 2s ease-in;
            cursor: pointer;


            color: white;
            text-decoration: none;
            font-weight: 700;
            font-size: 18px;

            &:hover {
                text-decoration: none;
            }


            h6 {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                color: white;
                width: 40px;
            }
        }

        .button {
            position: absolute;
            margin: auto;
            padding: 8px;
            top: 20px;
            right: 0;
            bottom: 0;
            left: 0;
            width: inherit;
            height: 40px;
            text-align: center;
            animation: fadeIn 2s ease-in;
            outline: none;

            a {
                padding: 5px 20px;
                border-radius: 10px;
                color: white;
                letter-spacing: 0.05em;
                text-decoration: none;
                font-size: 10px;
                transition: all 1s;

                &:hover {
                    color: white;
                    background: crimson;
                }
            }
        }

        .ds-info {
            margin: auto;
            top: 120px;
            bottom: 0;
            right: 0;
            left: 0;
            width: inherit;
            height: 40px;
            display: flex;

            .pens,
            .projects,
            .posts {
                position: relative;
                left: -300px;
                width: calc(250px / 3);
                text-align: center;
                color: white;
                animation: fadeInMove 2s;
                animation-fill-mode: forwards;

                h6 {
                    text-transform: uppercase;
                    color: crimson;
                }

                p {
                    font-size: 12px;
                }
            }

            .ds {
                &:nth-of-type(2) {
                    animation-delay: .5s;
                }

                &:nth-of-type(1) {
                    animation-delay: 1s;
                }
            }
        }

        .ds-skill {
            position: absolute;
            margin: auto;
            top: 155px;
            bottom: 10px;
            right: 0;
            left: 0;
            width: 200px;
            height: 100px;
            animation: mvBottom 1.5s;

            h6 {
                margin-bottom: 5px;
                font-size: 0.8em;
                text-transform: uppercase;
                color: rgb(255, 255, 255);
                user-select: none;
            }


            .essence-holder {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                height: auto;
                gap: 10px;

                .essence {
                    display: flex;
                    justify-content: left;
                    width: 100%;
                    height: 40px;
                    gap: 10px;
                    cursor: pointer;


                    &:hover {

                        .icon {
                            background: linear-gradient(180deg, #000 0%, white 130%);
                        }

                    }

                    .icon {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        border: 5px outset #39463a;
                        line-height: 35px;
                        background: linear-gradient(180deg, #000 0%, rgb(69, 184, 2) 130%);
                    }

                    .text {
                        line-height: 35px;
                        user-select: none;
                        color: var(--always-white)
                    }


                }
            }


        }
    }

    @keyframes fadeInMove {
        0% {
            opacity: 0;
            left: -300px;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes htmlSkill {
        0% {
            width: 0;
        }

        100% {
            width: 95%;
        }
    }

    @keyframes cssSkill {
        0% {
            width: 0;
        }

        100% {
            width: 90%;
        }
    }

    @keyframes jsSkill {
        0% {
            width: 0;
        }

        100% {
            width: 75%;
        }
    }

    @keyframes mvBottom {
        0% {
            bottom: -150px;
        }

        100% {
            bottom: 10px;
        }
    }

    @keyframes mvTop {
        0% {
            top: -150px;
        }

        100% {
            top: 20px;
        }
    }

    @keyframes dsTop {
        0% {
            top: -150px;
        }

        100% {
            top: 0;
        }
    }


}