
$theme-path: "../assets/images/core/";
[data-theme="white"] {
    --transparent: rgba(0, 0, 0, 0);
    --always-black : rgb(0, 0, 0);
    --always-white : rgb(255, 255, 255);
    --always-grey-l1 : rgb(197, 197, 197);
    --always-red : rgb(255,0,0);


    /*Theme name */

    --primary-l5: #feffff  ;
    --primary-l4: #fcfeff  ;
    --primary-l3: #f9fdff  ;
    --primary-l2: #f6fbff  ;
    --primary-l1: #f3faff  ; 

    --primary: #eff9ff;
    
    --primary-d1: #bee6ff ;
    --primary-d2: #8dd3ff ;
    --primary-d3: #5bc0ff ;
    --primary-d4: #2aadff ;
    --primary-d5: #0099f7 ; 
    
    --border-theme: #0099f7;
    --border-theme-light: #8dd3ff;
    
    --primary-light: #feffff;
    --primary-dark: #0096f3;

    --secondary: #216fbd;
    --secondary-light: #73adfd;
    --secondary-dark: #215a9c;

    --primary-text: #222222;
    --primary-light-text: #33b1ff;
    --primary-dark-text: #000000;

    --secondary-text: #ffffff;
    --secondary-light-text: #000000;
    --secondary-dark-text: #ffffff;

    --disabled: #dadada;
    --disabled-text: #b9b8b8;

    --valid: rgb(41, 175, 41);
    --invalid: rgb(180, 71, 38);

    /*Borders*/
    --radius: 9px;
    --radius-smaller: 6px;
    --radius-bigger: 11px;

    --font-size: 14px;
    --font-size--small: 0.8rem;
    --font-size--large: 1.2rem;

    /*
    --bg-color: #ffffff;
    --bg-color--nav: #414a50;
    --bg-color--nav--drop-down: #555;
    --bg-color--header: rgb(123, 104, 238); //#7b68ee
    --bg-color--header-light: #b096ff; //Light
    --bg-color--header-dark: #443dbb; //Dark
    --bg-color--product-tile: #ffffff;


    --input-color: #f2f2f2;
    --input-color--font: #020202;

    --input-disabled-color: #a3a3a3;

    --radius: 15px;
    --border-color: none;


    --font-size--h1: 1.2rem;
    --font-color: #020202;
    --font-color--inv: #ffffff;
    --font-nav-color: #f2f2f2;
    --font-header-color: #f2f2f2;
    --font-color-tile: #020202;
    --font-color-tile-tags: #f2f2f2;


    --button-color: #f2f2f2;
    --button-color-font: #f2f2f2;

    --button-disabled-color: #a3a3a3;

*/
}

.white_fury {
    box-sizing: border-box;
    background: var(--primary) url('#{$theme-path}background_white_fury.png') repeat scroll 0 0;
}