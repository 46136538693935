
.minicart {
    float: right;
    display: block;
    border: none;
    cursor: pointer;
    height: 40px;
    border-bottom: 4px solid var(--transparent);

    &:hover {
        //background-color: var(--primary-light);
        //color: var(--primary-light-text);
        border-bottom: 4px solid var(--secondary);
    }

    .minicart_icon {
        border: none;
        cursor: pointer;
        float: left;
        padding: 8px 10px 8px 5px;     
        height: 40px; 
    }

    .minicart_animate {    
        animation: cart_anim 1050ms linear;  
    }

    .minicart_value {
        font-size: 1.2rem;
        border: none;
        cursor: pointer;
        float: right;
        padding: 0px 10px 0px 0px;
        margin: auto 0px auto 0px;
        min-width: 20px;
        height: 100%;
        line-height: 40px;
        text-align: right;
    }

    @keyframes cart_anim {
        0% {
            transform: rotate(25deg);
        }

        15% {
            transform: rotate(-25deg);
        }

        30% {
            transform: rotate(25deg);
        }

        45% {
            transform: rotate(-25deg);
        }
        
        60% {
            transform: rotate(25deg);
        }

        75% {
            transform: rotate(-25deg);
        }

        100% {
            transform: rotate(0);
        }
    }
}

