.ButtonGlass {

    width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .btn {
        cursor: pointer;
        position: relative;
        min-width: 155px;
        width: max-content;
        height: 50px;
        margin: 20px;

        &:hover {
            .text {

                // letter-spacing: 3px;

                &::before {
                    transform: skewX(45deg) translateX(200%);
                }

            }

            &::before {
                border-radius: 70px;
                opacity: 0.25;
                //transition-delay: 0.2s;
                //opacity: 0.5;

            }

        }


        &::before {
            content: '';
            position: absolute;
            left: 10%;
            width: 90%;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            //  background: rgb(0, 255, 0);
            opacity: 0.2;
            border-radius: 10px;
            transition: 0.5s;
            filter: blur(5px);
        }

        .text {

            position: absolute;
            padding-left: 18px;
            padding-right: 18px;
            top: 0;
            left: 0;
            width: max-content;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 30px;
            color: #fff;
            z-index: 1;
            font-weight: 400;
            letter-spacing: 1px;
            text-decoration: none;
            overflow: hidden;
            transition: 0.5s;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
                transform: skewX(45deg) translateX(0);
                transition: 0.5s;
            }


        }

    }

}