@import '../../../styles/mixins.scss';

.AnimatedDrop {
    position: relative;
    display: block;
    width: 100%;
    //text-align: center;
    margin: 0px auto 0px auto;
    //padding: 15px 0px 0px 0px;
    overflow: hidden;
    border-radius: 0px 0px var(--radius) var(--radius);
    //background-color: var(--primary-light);


    .holder {
        position: relative;
        animation: drop-anim 5s ease-in-out 1s normal forwards;

        left: 0%;
        opacity: 0;
    }


    .drop-img {
        width: 256px;
        height: 256px;
    }


    .BubbleText {
        position: absolute;
        animation: drop-text 8s ease-in-out 1s normal forwards;
        left: -180px;
        top: -20px;
        opacity: 0.9;

    }


    @keyframes drop-anim {
        //from {left: 0%;} 
        //to{left: calc(40% - 128px)}

        0% {
            left: 0%;
            opacity: 0;
        }


        100% {
            opacity: 1;
            left: calc(50% - 128px)
        }

    }


    @keyframes drop-text {
        //from {left: 0%;} 
        //to{left: calc(40% - 128px)}

        0% {
            opacity: 0.9;
        }

        50% {
            opacity: 0.9;
        }


        100% {
            opacity: 0;
        }

    }

    @include tablet {

        .BubbleText {
            position: absolute;
            left: -10px;
    
        }
    

    }

}