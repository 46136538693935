@import '../../../styles/mixins.scss';

$background-hover: #ffffff;
$background-darker: #c7c7c7;


.SearchCmp {
    display: block;
    float: right;
    width: 260px;
    //max-width: 260px;
    //min-width: 260px;//0px;
    padding-right: 10px;
    margin: auto 0px auto 0px;
    transition: width 2s;
    height: 100%;

    input {
        display: block;
        height: 24px;
        width: 100%;
        background-color: $background-darker;
        color: black;
        border-radius: var(--radius);
        padding: 0px 10px;

        &:hover {
            background-color: $background-hover;
        }

    }

    .search_icon {
        position: absolute;
        right: 5px;
        top: 5px;
        height: 24px;
        width: 24px;
    }

    .search_quantity {
        color: black;
        font-size: 0.8rem;
        text-align: center;
        color: var(--primary);
        margin: 0px 0px 10px 0px;
    }

    .search_results {
        display: block;
        position: fixed;
        background-color: #f9f9f9;
        margin-top: 1px;
        padding: 0px 0px 0px 0px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 11;
        border-radius: var(--radius) var(--radius);
        width: inherit;
        max-height: 80vh;
        scroll-behavior: auto;
        animation: fadeIn 0.8s;
        z-index: 210;

        .search_entry,
        .entry_more {
            float: none;
            color: black;
            text-decoration: none;
            display: block;
            text-align: left;
            padding: 5px 15px;

            &:hover {
                background-color: #ddd;
            }

            &:last-child {
                border-radius: 0px 0px var(--radius) var(--radius);
            }

            a {
                vertical-align: middle;
                margin: auto 0px auto 0px;
            }

            .entry_image {
                display: inline-block;
                height: 2rem;
                width: 2rem;
                margin: 0px 15px 0px 0px;
            }

            .entry_data {
                display: inline-block;

                .entry_caregory {
                    font-size: 0.6rem;
                    line-height: 0.6rem;
                    color: rgb(125, 125, 125);
                    margin: 3px 0px 0px 0px;
                }

                .entry_display {
                    width: auto;
                    color: rgb(125, 125, 125);
                }

            }

        }

        .entry_more {
            font-size: 0.8rem;
            padding: 10px 15px 15px 0px;
            text-align: center;
        }

        p {
            margin-top: 3px;

            &:hover {
                background-color: unset;
            }
        }

    }

    @include tablet {
        //max-width: unset;
        margin: auto auto auto auto;
        width: 100%;//0px;
        padding: 0px 15px;
        text-align: center;

    }

}
