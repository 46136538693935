.ProgressBarCmp {

    .prog.striped>.progBar {
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 60px 60px;
        border-radius: var(--radius);
        height: 100%;
    }

    @keyframes progBar {
        from {
            background-position: 0 0;
        }

        to {
            background-position: 60px 0;
        }
    }

    .progressBarText {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: visible;
        height: 100%;
        line-height: 100%;
        text-align: center;
        padding-left: 8px;
    }

    .prog.striped.animated>.progBar {
        animation: progBar 1s linear infinite;
    }

    .prog.red>.progBar {
        background-color: #ad0000;
    }

    .prog.light_red>.progBar {
        background-color: #e90808;
    }


    .prog.yellow>.progBar {
        background-color: #c4c200;
    }

    .prog.blue>.progBar {
        background-color: #0000ff;
    }

    .prog.orange>.progBar {
        background-color: #ffa500;
    }

    .prog.green>.progBar {
        background-color: #008000;
    }

    .prog.primary>.progBar {
        background-color: var(--primary-l2);
    }

    .prog.secondary>.progBar {
        background-color: var(--primary-d1);
    }

}