.InputTextAreaFun {

    border: 1px inset var(--primary-l1);
    border-radius: var(--radius);
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    vertical-align: middle;
    padding: 6px;
    color: var(--primary-text);
    background-color: var(--primary-d1);

    &::placeholder {
        text-align: center;
        color: var(--primary-dark-text);
    }

}