.CardHolderCmp {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 32px auto 32px;
    grid-template-areas: "header""content""footer";

    background-color: var(--primary-l2);
    color: var(--primary-text);
    border: 2px inset var(--border-theme);
    border-radius: var(--radius);
    margin: 10px 0px;
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.59);

    .holder_title {
        grid-area: header;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 32px;
        grid-template-areas: "texts goback";

        width: 100%;
        height: 32px;
        line-height: 32px;
        font-size: 1rem;
        font-weight: bold;
        padding: 0px 10px 0px 10px;
        border-bottom: 1px solid var(--border-theme);
        border-radius: var(--radius) var(--radius) 0 0;
        background-color: var(--primary-l1);

        .text {
            grid-area: texts;
            margin-right: 10px;
            line-height: 32px;
            align-self: left;
            justify-self: left;
        }

        .goBack {
            grid-area: goback;
            cursor: pointer;
            justify-self: right;
            align-self: center;
            
            text-align: center;
            width: 100px;
            line-height: 24px;
            height: 24px;
            
            background-image: linear-gradient(to right, var(--secondary-light) 0%, var(--secondary) 100%);
            border-radius: var(--radius-smaller);
            opacity: 0.8;
            margin: auto 0;

            &:hover {
                opacity: 1;
                background-image: unset;
                background-color: var(--secondary-light);
            }

        }
    }

    .holder_content {
        grid-area: content;
        width: 100%;
        margin: 0px;
        background-color: var(--primary-l2);
        min-height: 150px;
        padding: 0px;
    }

    .holder_footer {
        grid-area: footer;
        width: 100%;
        height: 32px;
        font-size: 0.9rem;
        line-height: 32px;
        background-color: var(--primary-l1);
        color: var(--primary-text);
        border-top: 1px solid var(--border-theme);
        border-radius: 0 0 var(--radius) var(--radius);
        text-align: center;
        padding: 0px 10px 0px 10px;
    }
}