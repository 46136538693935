.CPPage {
    display: block;
    text-align: center;
    padding-top: 25px;

      
    .selected {
        background: radial-gradient(circle, rgb(255, 176, 6) 0%, rgb(255, 216, 0) 100%);
        color: #000;
        box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.2);
    }

    input {
        width: 100%;
        box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.6);
        padding: 3px;
        margin: 2px;
        color: #000;
    }



    .holder {
        border-radius: var(--radius);
        background-color: var(--primary-l1);
        width: 100%;
        max-width: 950px;
        min-width: 350px;
        min-height: 600px;
        //padding: 8px ;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        //row-gap: 10px;
        //column-gap: 10px;
        grid-template-columns: 170px auto 170px;
        //grid-template-rows: 100px; //auto fit-content auto;
        grid-template-areas:
            "left_top top_middle top_middle right_top"
            "left_middle middle_box middle_box right_middle"
            "left_middle colourType colourSetting right_middle"
            "left_middle colours colours right_middle"
            "left_middle colours colours right_middle"
            "left_middle colours colours right_middle"
            "finish finish finish finish"
        ;



        .middle_box {
            grid-area: middle_box;
            //background: rgba(255, 255, 255, 0.05);
            background-color: #fff;
            //box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            //border-top: 1px solid rgba(255, 255, 255, 0.1);
            //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
           //box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
            position: relative;

            //border-radius: 16px;
            box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
            padding: 5px;

            .img {
                margin: auto auto;
                width: 100%;
                height: auto;
                border-radius: 15px;
                padding: 5px;
           
            }


            .tag_pd_r {
                position: absolute;
                height: 30px;
                line-height: 30px;
                left: 10%;
                top: 10px;
                color: #747474;
    
            }

            .tag_pd_l {
                position: absolute;
                height: 30px;
                line-height: 30px;
                right: 10%;
                top: 10px;
                color: #747474;
    
            }


            .pd_r {
                position: absolute;
                height: 30px;
                line-height: 30px;
                left: 10%;
                top: 40px;
                color: #000;
                width: 110px;
    
            
            }

            
            .pd_l {
                position: absolute;
                height: 30px;
                line-height: 30px;
                right: 10%;
                top: 40px;
                color: #000;
                width: 110px;
    
            }

            
            .tag_sh_r {
                position: absolute;
                height: 30px;
                line-height: 30px;
                right: 10%;
                bottom: 16%;
                color: #747474;
    
            }

            .tag_sh_l {
                position: absolute;
                height: 30px;
                line-height: 30px;
                left: 10%;
                bottom: 16%;color: #747474;
    
            }

            .sh_r {
                position: absolute;
                height: 30px;
                line-height: 30px;
                left: 10%;
                bottom: 8%;
                color: #000;
                width: 110px;
    
            }

            
            .sh_l {
                position: absolute;
                height: 30px;
                line-height: 30px;
            
                right: 10%;
                bottom: 8%;
                color: #000;
                width: 110px;
    
            }
        }

        .left_top {
            grid-area: left_top;

            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            //border-radius: 16px;
            padding: 8px;


            .title {
                width: 100%;
                margin-bottom: 10px;
            }


            .img {
                width: 60px;
                height: 60px;
                padding: 5px;
                opacity: 0.5;
            }


            .sv {
                margin-bottom: 0px;
                border-radius: 16px 16px 0px 0px;
                box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
                border-bottom: unset;
                cursor: pointer;
                //opacity: 0.3;
                font-size: 0.5;
                z-index: 2;
                user-select: none;
                text-align: left;
                padding: 15px;

                &:hover {
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
                }
            }

            .mf {
                border-top: unset;
                margin: 0px;
                border-radius: 0px 0px 16px 16px;
                box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                user-select: none;
                text-align: left;
                padding: 15px;
                // background-color: rgba(87, 245, 87, 0.2);

                &:hover {
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);

                }

            }

        }

        .top_middle {

            grid-area: top_middle;
            display: grid;

            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            padding: 10px;
            grid-template-columns: 70px 40px 70px 40px auto auto 40px 70px 40px 70px;
            grid-template-areas:
                "title title title title title title title title title title"
                "tag_l tag_l tag_l tag_l eye_l eye_r tag_r tag_r tag_r tag_r"
                ". . sph_l sph_tag_l eye_l eye_r sph_tag_r sph_r . ."
                "axis_l axis_tag_l cyl_l cyl_tag_l eye_l eye_r cyl_tag_r cyl_r axis_tag_r axis_r"
                ". . add_l add_tag_l eye_l eye_r add_tag_r add_r . ."
            ;

          

            .title {
                grid-area: title;
                align-self: center;
                
                
            }

          

            .tag_l {
                grid-area: tag_l;
                align-self: center;
                text-align: center;
                height: 30px;
                line-height: 30px;
            
            }

            .tag_r {
                grid-area: tag_r;
                align-self: center;
                text-align: center;
                height: 30px;
                line-height: 30px;
            
            }

            .eye_l {
                grid-area: eye_l;
                align-self: center;
            }

            .eye_r {
                grid-area: eye_r;
                align-self: center;
            }


            .sph_tag_l {
                grid-area: sph_tag_l;
                height: 30px;
                line-height: 30px;
            }

            .sph_l {
                grid-area: sph_l;
            }


            .sph_tag_r {
                grid-area: sph_tag_r;
                height: 30px;
                line-height: 30px;
            }

            .sph_r {
                grid-area: sph_r;
            }

            .cyl_tag_l {
                grid-area: cyl_tag_l;
                height: 30px;
                line-height: 30px;
            }

            .cyl_l {
                grid-area: cyl_l;
            }


            .cyl_tag_r {
                grid-area: cyl_tag_r;
                height: 30px;
                line-height: 30px;
            }

            .cyl_r {
                grid-area: cyl_r;
            }

            .axis_tag_l {
                grid-area: axis_tag_l;
                height: 30px;
                line-height: 30px;
            }

            .axis_l {
                grid-area: axis_l;
            }


            .axis_tag_r {
                grid-area: axis_tag_r;
                height: 30px;
                line-height: 30px;
                text-align: right;
            }

            .axis_r {
                grid-area: axis_r;
            }

            .add_tag_l {
                grid-area: add_tag_l;
                height: 30px;
                line-height: 30px;
            }

            .add_l {
                grid-area: add_l;
            }


            .add_tag_r {
                grid-area: add_tag_r;
                height: 30px;
                line-height: 30px;
            }

            .add_r {
                grid-area: add_r;
            }

        }

        .right_top {
            grid-area: right_top;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;
            align-items: center;
            padding-left: 5px;
            font-weight: bold;

            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding: 5px;

            .price_title {

                line-height: 2rem;
            }

            .cp_price {
                width: 100%;
                text-align: right;
                font-size: 2rem;
                line-height: 2rem;
                color: rgb(71, 214, 71);
            }

            .frm_price {
                width: 100%;
                text-align: right;
                font-size: 1rem;
                line-height: 1rem;
                color: rgb(71, 214, 71);
            }

            .lens_price {
                width: 100%;
                text-align: right;
                font-size: 1rem;
                line-height: 1rem;
                color: rgb(71, 214, 71);
            }
        }


        .left_middle {

            grid-area: left_middle;
            position: relative;
            align-self: center;
            display: inline-block;

            width: 100%;
            height: 100%;

            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            text-align: center;
            //border-radius: 16px;
            padding: 10px 10px;

            .title {
                width: 100%;
                margin-bottom: 10px;
            }

            .feature {
                display: inline-block;
                position: relative;
                cursor: pointer;
                width: 100%;
                min-height: 50px;

                ///background: rgba(255, 255, 255, 0.05);
                box-shadow: inset 0 0px 10px rgba(0, 0, 0, 0.2);
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                //border-radius: 6%;
                text-align: center;
                user-select: none;

                &:nth-child(2) {
                    border-radius: 16px 16px 0px 0px;
                    margin: 5px 0px 0px 0px;
                }

                &:last-child {
                    border-radius: 0 0 16px 16px;
                    margin: 0px 0px 5px 0px;
                }

                .img {
                    width: 50px;
                    height: 50px;
                    padding: 5px;
                    opacity: 0.5;
                }

                .bgText {
                    //position: absolute;
                    //top: 15px;
                    //left: 5px;
                    font-size: 1.0em;
                    color: rgb(48, 45, 45);
                    margin: unset;
                    user-select: none;
                    font-weight: bold;
                    text-align: center;
                    padding: 0px 5px 5px 5px;
                    min-height: 1em;

                }


                &:hover {
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);

                    .img {
                        opacity: 1;
                    }
                }
            }
        }

        .right_middle {

            grid-area: right_middle;
            position: relative;
            align-self: center;
            display: inline-block;

            width: 100%;
            height: 100%;

            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            text-align: center;
            //border-radius: 16px;
            padding: 10px 5px;

            .title {
                width: 100%;
                margin-bottom: 10px;
            }

            .space {
                display: inline-block;
                width: 100%;
                height: 40px;
            }

            .config {
                display: inline-block;
                position: relative;
                width: 90%;
                height: 70px;
                margin: 5px 0px;

                background: rgba(255, 255, 255, 0.05);
                //box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                text-align: center;
                line-height: 70px;


                .img {
                    width: 60px;
                    height: 60px;
                    border-radius: 15%;
                    padding: 5px;
                    opacity: 0.5;
                }


                .bgText {
                    position: absolute;
                    top: 25px;
                    left: 5px;
                    font-size: 1em;
                    color: rgb(48, 45, 45);
                    margin: unset;
                    user-select: none;
                    font-weight: bold;
                }


                .tag {
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    padding: 4px 0px;
                    color: rgb(24, 24, 24);
                    font-size: 0.9rem;
                }


                .tag_description {
                    width: 100%;
                    text-align: center;
                    padding: 3px 2px 2px 2px;
                    color: rgb(43, 43, 43);
                    font-size: 0.9rem;
                }


                .price {
                    width: 100%;
                    text-align: center;
                    padding: 3px 2px;
                    font-weight: bold;
                    font-size: 1.1rem;
                }

            }


            .subtracts {
                margin-bottom: 0px;
                border-radius: 16px 16px 0px 0px;
                box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
                border-bottom: unset;
                cursor: pointer;
                //opacity: 0.3;
                font-size: 0.5;
                z-index: 2;
                user-select: none;

                &:hover {
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
                    opacity: 1;
                }

                .price {
                    color: rgb(160, 48, 48);
                    font-weight: bold;
                }


            }

            .current_value {
                margin: 0px;
                border-radius: 0px;
                border: unset;
                padding-top: 15px;
                text-align: center;
                //border: 1px solid rgba(122, 122, 122, 1);
                box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.2);
                background: radial-gradient(circle, rgb(255, 176, 6) 0%, rgb(255, 216, 0) 100%);
                user-select: none;

                .tag {
                    color: #000;
                    font-size: 1rem;
                }


                .tag_description {
                    color: rgb(43, 43, 43);
                    font-size: 1rem;
                }

                .img {
                    position: absolute;
                    top: 0px;
                    right: -12px;
                    width: auto;
                    height: 100%;
                    padding: 15px;
                    opacity: 0.15;
                    margin: auto;
                }


            }


            .add {
                border-top: unset;
                margin: 0px;
                border-radius: 0px 0px 16px 16px;
                box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                user-select: none;

                // background-color: rgba(87, 245, 87, 0.2);


                &:hover {
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);

                    .img {
                        opacity: 1;
                    }
                }


                .price {
                    color: rgb(106, 173, 106);
                }
            }

        }
    }

    .colourType {

        grid-area: colourType;

        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 5px;


        .title {
            width: 100%;
            margin-bottom: 10px;
        }

        .feature {
            display: inline-block;
            position: relative;
            cursor: pointer;
            width: 45%;
            height: 50px;
           
           // background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            text-align: right;


            &:nth-child(2) {
                border-radius: 16px 0  0 16px;
            }

            &:last-child {
                border-radius: 0 16px 16px 0 ;
            }

            .img {
                width: 50px;
                height: 50px;
                border-radius: 15%;
                padding: 5px;
                opacity: 0.5;
            }

            .bgText {
                position: absolute;
                top: 15px;
                left: 10px;
                font-size: 1em;
                color: rgb(48, 45, 45);
                margin: unset;
                user-select: none;
                font-weight: bold;
            }

            &:hover {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);

                .img {
                    opacity: 1;
                }
            }

        }
    }

    .colours {

        grid-area: colours;

        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 11x 25px rgba(0, 0, 0, 0.2);
        //border-top: 1px solid rgba(255, 255, 255, 0.1);
        //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 8px 8px 12px 8px;

        align-self: center;
        height: 100%;
       
        background-color: rgba(255,255,255,1);
        //border-radius: 0px 0px 16px 16px;
        box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.7);

        .title {
            width: 100%;
            margin-bottom: 10px;
            color: #000;
        }

        .color_holder {
            padding: 10px;
        }

        .color {
            display: inline-block;
            border-radius: 50%;
            cursor: pointer;
            width: 100px;
            height: 100px;
            margin: 5px 5px;
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
            
            &:hover {
                box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
            }
        }

        .red {
            background: red;
        }

        .red_gradient {
            background: linear-gradient(0deg, rgba(255, 0, 0, 0.1) 0%, red 80%);
        }

        .blue {
            background: rgb(0, 110, 255);
        }

        .blue_gradient {
            background: linear-gradient(0deg, rgba(255, 0, 0, 0.1) 0%, rgb(0, 102, 255) 80%);
        }

        
        .yellow {
            background: rgb(251, 255, 0);
        }

        .yellow_gradient {
            background: linear-gradient(0deg, rgba(255, 0, 0, 0.1) 0%, rgb(209, 245, 9) 80%);
        }

        .orange {
            background: rgb(206, 160, 11);
        }

        .orange_gradient {
            background: linear-gradient(0deg, rgba(255, 0, 0, 0.1) 0%, rgb(187, 132, 14) 80%);
        }


        .green {
            background: rgb(29, 158, 29);
        }


        .green_gradient {
            background: linear-gradient(0deg, rgba(29, 158, 29, 0.2) 0%, rgb(29, 158, 29) 80%);
        }
    }

    .colourSetting {

        grid-area: colourSetting;

        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 11px 25px rgba(0, 0, 0, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 5px;

        .title{
            margin-bottom: 10px;
        }

        .intensity{
            height: 60%;
            width: 80%;
            text-align: center;
            font-weight: bold;
            font-size: 1.5rem;
            color: rgb(116, 116, 116);
            border-radius: 16px;

        }

    }



    .finish {

        grid-area: finish;
        position: relative;
        align-self: center;

        .btn {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 auto 0 auto;
            height: 80px;
            width: 100%;
            border-radius: 0 0 12px 12px;

            overflow: hidden;

            justify-content: center;
            align-items: center;

            background: rgba(255, 255, 255, 0.05);
            //box-shadow: inset 0 11px 25px rgba(0, 0, 0, 0.2);
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            text-align: center;
            background: radial-gradient(circle, rgb(255, 176, 6) 0%, rgb(255, 230, 0) 100%);
            box-shadow: inset 0 11px 15px rgba(0, 0, 0, 0.3);

            &:hover {
                box-shadow: inset 0 11px 10px rgba(0, 0, 0, 0.3);
            }

            .text {
                font-weight: bold;
                color: #000;

            }


        }


    }

    

}