@import '../../../styles/mixins.scss';

.PromotionsPage {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 10px;
  text-align: center;

  .promotion_box {
    display: inline-block;
    position: relative;
    width: 31.0%;
    min-width: 220px;
    padding: 15px;
    margin-bottom: 15px;
    margin-left: 1%;
    //FIX background-image: url("/images/core/pattern-fade.png");
    background-size: 100%;
    background-color: white;
    border-radius: var(--radius);
    border: 2px solid var(--border-theme);
    
    &:hover {
      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.20);
    }

  }
  
  .promotion_box__name {
    display: block;
    float: left;
    width: 100%;
    color: black;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.5;
    font-weight: 700;
  }

  .promotion_box__description {
    display: block;
    float: left;
    width: 100%;
    color: black;
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1rem;
    height: 2rem;
  }


  .promotion_box__time {
    display: block;
    float: left;
    width: 100%;
    font-weight: bold;
    text-align: center;
    color: red;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .promotion_box__image {
    width: auto;
    max-width: 100%;
    display: block;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mobile {
    
    .promotion_box {
      width: 100%;
      min-width: 100%;
      margin: 0px 5px 0px 0px;
      padding-top: 10px;
    }

  }

}