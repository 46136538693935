@import '../../../../styles/mixins.scss';

.CartSummaryCmp {
   // display: grid;
   // grid-template-columns: fit-content;
   // grid-template-rows: auto;
   // grid-template-areas: "summary";
    //justify-items: end;

    .grid {
        //grid-area: summary;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1.2rem 1rem 1rem 1rem 1rem 2rem 45px auto;
        grid-row-gap: 8px;
        grid-template-areas:
            "title title"
            "tag1 cartBefore"
            "tag2 cartDiscount"
            "tag2b cartDelivery"
            "tag3 cartVouchers"
            "tag4 cartValue"
            "freeShipment freeShipment"
            "purchaseBtn purchaseBtn";

        justify-items: end;
        padding: 10px 20px 10px 20px;
        border-radius: var(--radius);
        border: 2px solid var(--border-theme);
        background-color: var(--primary-l1);
        width: fit-content;
        column-gap: 3px;

        .title {
            grid-area: title;
            font-weight: bold;
            line-height: 1rem;
            margin-bottom: 12px;
        }

        .tag1 {
            grid-area: tag1;
            font-size: 0.9rem;
            text-align: right;
        }

        .tag2 {
            grid-area: tag2;
            font-size: 0.9rem;
            text-align: right;
        }

        .tag2b {
            grid-area: tag2b;
            font-size: 0.9rem;
            text-align: right;
        }

        .tag3 {
            grid-area: tag3;
            font-size: 0.9rem;
            text-align: right;
        }

        .tag4 {
            grid-area: tag4;
            font-size: 1.2rem;
            text-align: right;
        }


        .cartValueBeforeDiscounts {
            grid-area: cartBefore;
            font-size: 0.9rem;
            text-align: right;
            color: var(--primary-text);
        }

        .cartDiscount {
            grid-area: cartDiscount;
            text-align: right;
            font-size: 0.9rem;
            color: rgb(50, 223, 50);

        }

        .cartDelivery {
            grid-area: cartDelivery;
            text-align: right;
            font-size: 0.9rem;
        }

        .cartVouchers {
            grid-area: cartVouchers;
            text-align: right;
            font-size: 0.9rem;
        }

        .cartValue {
            grid-area: cartValue;
            text-align: right;
            font-size: 1.1rem;
            font-size: bold;
            text-decoration: underline;
            line-height: 1.1rem;
        }

        .purchaseHolder {
            grid-area: purchaseBtn;
            justify-self: center;
            padding: 5px;

            .purchaseBtn {
                min-width: 200px;
                height: 100%;
            }
        }

        .freeShipment {
            grid-area: freeShipment;
            font-size: 0.9rem;
            text-align: center;
            justify-self: center;
            line-height: 2rem;
            background-color: var(--primary-d1);
            border-radius: var(--radius);
            height: 2rem;
            line-height: 2rem;

            .progressBarText {
                height: 2rem;
                line-height: 2rem;
                padding-left: 5px;
                padding-right: 5px;
            }
        }

    }


    @include mobile {
        width: 100%;
        grid-template-columns: 100%;
        //grid-template-areas: "summary";

        .grid {
            width: 100%;
            grid-template-areas: auto 100%;

            .title{
                text-align: left;
                justify-self: start;
            }

            .tag1, .tag2, .tag2b, .tag3, .tag4 {
                justify-self: start;
                text-align: left;
            }
    
        }
    
    }

}