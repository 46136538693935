@import '../../../../../styles/mixins.scss';

.Instore {

    margin: 10px 0px 10px 0px;
    background-color: var(--primary-l1);
    border-radius: var(--radius);
    width: 100%;

    .hd_title {
        font-weight: bold;
        font-size: 1.2rem;
        border-bottom: 1px solid var(--border-theme);
        border-radius: var(--radius) var(--radius) 0 0;
        padding: 10px 10px;
        line-height: 1.2rem;
        user-select: none;
        height: 41px;
    }

    .ft_footer {
        text-align: center;
        border-top: 1px solid var(--border-theme);
        border-radius: 0 0 var(--radius) var(--radius);
        padding: 10px 10px;
        line-height: 1.2rem;
        height: 41px;
    }

    .infoHolder {
        display: grid;
        grid-template-columns: 180px auto;
        grid-template-rows: auto auto auto 60px;
        grid-template-areas:
            "search search"
            "cities storeInfo"
            "pickedInfo pickedInfo"
            "actionBar actionBar";

        padding: 7px;

        .search {
            grid-area: search;
            margin-bottom: 10px;
            margin-top: 3px;
            width: 100%;

            .searchInput {
                width: 79%;
                height: 40px;
                margin-right: 1%;
            }

            .searchButton {
                width: 20%;
                height: 40px;
                min-width: 120px;
            }
        }

        .paymentButton {
            grid-area: actionBar;
            width: 100%;
            height: 100%;
            line-height: 60px;


            .payButton {
                padding: 10px;
                margin: auto;
                width: 100%;
                height: 40px;
            }
        }

        .cityHolder {
            grid-area: cities;
            border-top: 1px solid var(--border-theme);
            border-left: 1px solid var(--border-theme);
            border-bottom: 1px solid var(--border-theme);
            border-radius: var(--radius) 0 0 var(--radius);
            background-color: var(--primary-l2);
            height: 280px;

            .tagCity {
                font-weight: bold;
                border-bottom: 1px solid var(--border-theme);
                padding: 7px;
            }

            .cities {
                overflow-y: auto;
                height: 230px;

                .city {
                    cursor: pointer;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding: 7px;

                    &:hover {
                        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.59);
                    }

                }


            }



        }

        .searchResults {

            border: 1px solid var(--border-theme);
            border-radius: 0 var(--radius) var(--radius) 0;
            background-color: var(--primary-l2);

            height: 280px;

            .tagTitle {
                font-weight: bold;
                border-bottom: 1px solid var(--border-theme);
                padding: 7px;
            }

            .storeEntries {
                overflow-y: auto;
                height: 230px;

                .storeEntery {
                    cursor: pointer;
                    display: grid;
                    grid-template-columns: 40px auto auto;
                    grid-template-rows: 1rem 0.9rem 0.9rem;
                    grid-template-areas: "select storeCode ."
                        "select address city"
                        "select openHours openHours";

                    column-gap: 10px;
                    row-gap: 10px;
                    padding: 5px;

                    border-bottom: 1px solid var(--border-theme);

                    &:hover {
                        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.59);
                    }

                    .tagStoreCode {
                        grid-area: tagStoreCode;
                    }

                    .storeCode {
                        grid-area: storeCode;
                        line-height: 1rem;
                        font-weight: bold;
                        font-size: 1.1rem;
                    }

                    .name {
                        grid-area: name;
                    }

                    .tagAddress {
                        grid-area: tagAddress;
                    }

                    .address {
                        grid-area: address;
                        line-height: 0.9rem;
                        font-size: 0.9rem;
                    }

                    .tagCity {
                        grid-area: tagCity;
                    }

                    .city {
                        grid-area: city;
                    }

                    .code {
                        grid-area: code;
                        line-height: 0.9rem;
                        font-size: 0.9rem;
                    }

                    .tagOpenHours {
                        grid-area: tagOpenHours;
                    }

                    .openHours {
                        grid-area: openHours;
                        line-height: 0.9rem;
                        font-size: 0.9rem;
                    }

                    .select {
                        grid-area: select;
                        text-align: center;
                        align-self: center;

                        border-radius: var(--radius);
                        border: 1px outset var(--border-theme);
                        background-color: var(--secondary);

                        user-select: none;
                        width: 100%;
                        height: 100%;
                        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.59);

                        .img {
                            width: 90%;
                            filter: saturate(0);
                            margin: auto;
                            position: relative;
                            top: 1rem;
                            left: 2.5%;
                            text-align: center;

                        }

                        .storeSelected {
                            width: 95%;
                            filter: unset;
                        }

                        &:hover {
                            background-color: var(--secondary-light);

                            .img {
                                filter: saturate(2);
                            }

                        }
                    }

                }

            }
        }



        .pickedInfo {
            grid-area: pickedInfo;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: 1rem 1rem 1rem 1rem 1rem 1rem 1rem;
            grid-template-areas:
                "title"
                "tagName"
                "name"
                "tagStreet"
                "street"
                "tagOpenHours"
                "openHours";

            row-gap: 7px;
            padding: 5px 15px 15px 15px;
            margin: 10px 0px 0px 0px;
            width: 100%;
            min-width: 300px;

            .title {
                grid-area: title;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
                font-weight: bold;
            }

            .tagName {
                grid-area: tagName;
                user-select: none;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 7px;
                width: 100%;
            }

            .tagStreet {
                grid-area: tagStreet;
                user-select: none;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 7px;
                width: 100%;
            }

            .tagBulding {
                grid-area: tagBulding;
                user-select: none;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 7px;
                width: 100%;
            }

            .tagZipCode {
                grid-area: tagZipCode;
                user-select: none;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 7px;
                width: 100%;
            }

            .tagCity {
                grid-area: tagCity;
                user-select: none;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 7px;
                width: 100%;
            }

            .tagOpenHours {
                grid-area: tagOpenHours;
                user-select: none;
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 7px;
                width: 100%;
            }

            .name {
                grid-area: name;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
            }

            .street {
                grid-area: street;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
            }

            .bulding {
                grid-area: bulding;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
            }

            .zipCode {
                grid-area: zipCode;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
            }

            .city {
                grid-area: city;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
            }

            .openHours {
                grid-area: openHours;
                text-align: left;
                padding-left: 7px;
                height: 1rem;
            }


        }

    }


    @include mobile {
        .infoHolder {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto 180px auto;
            grid-template-areas:
                "search"
                "cities"
                "storeInfo";
            padding: 0px;
            border-radius: 0;
            width: 100%;

            .search {
                padding: 10px;
                border-radius: var(--radius) var(--radius) 0 0;

                .searchInput {
                    width: 79%;
                    max-width: 220px;
                    margin-right: 5px;
                }

                .searchButton {
                    min-width: 100px;
                }

            }

            .cityHolder {
                height: 180px;
                border-radius: var(--radius) var(--radius) 0 0;
            }

            .searchResults {
                border-radius: 0;

                .storeEntries .storeEntery .storeCode {
                    font-size: 1.0rem;
                }

            }

        }

        .storeEntery {
            grid-template-columns: auto;
            grid-template-rows: 1rem 1rem 1rem 1rem;
            grid-template-areas:
                "storeCode"
                "address"
                "city"
                "openHours";

        }



    }

}