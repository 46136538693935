.ThreeImagesCmp {
    display: grid;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 1rem;
    margin: 10px auto 10px auto;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100%;
    grid-template-areas: "limage cimage rimage";
    grid-gap: 10px;

    overflow: hidden;

    .limg,
    .cimg,
    .rimg {
        position: relative;
        grid-area: limage;
        text-align: left;
        vertical-align: middle;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        max-height: 100%;

        .text {
            position: absolute;
            top: 16px;
            padding: 8px 16px;
            opacity: 0.7;

            color: var(--always-white);
            //font-weight: bold;
            text-align: center;
            width: 100%;
            font-size: var(--font-size);

            background-color: var(--primary-dark);

        }

        .black_font {
            color: var(--always-black);

        }

        &:hover {
            .text {
                opacity: 0.9;
            }
        }
    }

    .cimg {
        grid-area: cimage;
        text-align: center;
        align-items: stretch;
    }

    .rimg {
        grid-area: rimage;
        text-align: right;
    }

    .img_holder {
        position: relative;
        width: 100%;
        height: 100%;
        animation: slide-show 5s ease-in-out 6s normal forwards;
        ;

        max-width: 400px;
        //max-height: 200px;
        max-height: 0px;
        border-radius: var(--radius);
        margin: 0px;
        padding: 0px;
        overflow: hidden;

        img {

            width: 100%;
            height: 100%;

            transform-origin: 50% 50%;
            transition: transform 2s, filter .5s ease-out;

           // &:hover {
            //    transform: scale(1.05);
            //}
        }
    }


    @keyframes slide-show {

        0% {

            max-height: 0px;
        }


        100% {
            max-height: 200px;

        }

    }

}