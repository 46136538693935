@import 'mixins.scss';
@import 'white_fury.scss';
@import 'dark_fusion.scss';

$theme-path: "../assets/images/core/";


* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    color: var(--primary-text);
    border: 0;
    font-size: 1rem;
    //font-family: Arial, Helvetica, sans-serif;
    //font-family: Georgia, serif;
    font-family: arial,sans-serif;
    line-height: 1rem;
    /*transition: color 0.4s ease-out;*/
    outline:none; 
}

html {
    /*Fonts*/
    --font-size: 16px;
    --font-size-small: 0.8rem;
    --font-size-large: 1.2rem;
    --font-size-h1: 1.2rem;

    --always-white: #f1ebeb;
    --always-black: black;
    --always-dark-gradient: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    --always-dark-overlay: rgba(0, 0, 0, 0.4);
    --always-light-overlay: rgba(255, 255, 255, 0.4);

    /* make sure it is at least as tall as the viewport */
    display: inline;
    position: relative;
    margin: 0px;
    padding: 0px;
    min-width: 100%;
    min-height: 100vh;
    font-size: var(--font-size);
    font-weight: normal;
    line-height: 1.0rem;

}



body {
    display: block;
    background: url('#{$theme-path}background_l.png') 0px 0px repeat-y,
    url('#{$theme-path}background_r.png') 100% 100% repeat-y;
    background-color: var(--primary-light);
    color: var(--primary-text);
    margin-bottom: 190px;
    margin-top: 0px;
    padding: 0px;
    transition: opacity 1.0s ease-in;

    a {
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            border: 0;
            outline: 0;
        }


        &::-moz-focus-inner {
            border: none;
        }
    }

    .waiting * {
        cursor: progress;
    }

    /*
    .content {
        display: block;
        width: 100%;
        min-height: 450px;
        //transition: all 0.4s ease-in-out;
    }*/

    .center {
        text-align: center;
    }

    img {
        width: auto;
        height: auto;
    }



    input[type=button] {
        background-color: var(--secondary);
        color: var(--secondary-text);
        border-style: inset;
        padding: 5px;
        border-radius: var(--radius);

        &:hover,
        &:active,
        &:focus {
            outline: 0;
            border: 0;
        }

    }

    form {
        width: auto;
        height: auto;
    }

    button {
        display: inline-block;
        cursor: pointer;
        user-select: none;
        color: var(--secondary-text);
        text-align: center;
        vertical-align: middle;
        /*width: 60px;*/
        /*height: 20px;*/
        padding: 6px;
        border-radius: var(--radius);
        line-height: 1rem;
        font-size: 1rem;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        text-decoration: none;
        text-transform: none;
        background-image: linear-gradient(to right, var(--secondary-light) 0%, var(--secondary) 100%);
        box-shadow: rgba(0, 0, 0, 0.52) 2px 2px 8px 0px;
        width: fit-content;

        //background-color: var(--secondary);
        border: 2px solid var(--secondary-dark);

        &:hover:not([disabled]) {
            background: var(--secondary-light);
            //  box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
        }

        &:disabled {
            background-color: var(--disabled);
            color: var(--disabled-text);
            cursor: auto;
        }

        &:focus {
            outline: none;
            border: 0px;
        }
    }

}


/*******DRAGGABLE**********/
[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

/*******************************/

table {
    border-collapse: collapse;
    border-radius: var(--radius);
    overflow: hidden;
    color: #212529;
    align-items: center;
    justify-content: center;
    box-shadow: 9px 11px 18px 2px rgba(0, 0, 0, 0.44);
    vertical-align: middle;

    tr {
        background-color: rgb(199, 239, 255);
    }

    tr:nth-child(2n) {
        background-color: rgb(182, 223, 253);
    }

    th {
        text-align: left;
        font-size: 1.1rem;
        color: #fff;
        line-height: 1.2rem;
        background-color: #00ad5f;
        padding: 8px 16px;
        vertical-align: middle;
        cursor: pointer;
    }

    td {
        line-height: 1.0rem;
        text-align: left;
        font-size: 1.0rem;
        padding: 8px 16px;
        vertical-align: middle;
    }

    input {
        border: 2px inset #020202;
        font-family: inherit;
    }

}

.table_with_filter {
    display: block;
    float: left;

    .input_holder {
        display: block;
        margin-bottom: 25px;
        height: 28px;

        input {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .text {
            padding-right: 10px;
            line-height: inherit;
        }

        button {
            display: block;
            float: right;
            margin-left: 5px;
        }

    }

}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: var(--radius);

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: var(--radius);
        }

    }

    input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0px;
        margin: 0px;
        border: none;
        border-radius: 0;

        &:checked+.slider {
            background-color: #2196F3;
        }

        &:focus+.slider {
            box-shadow: 0 0 1px #2196F3;
        }

        &:checked+.slider:before {
            -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
            transform: translateX(24px);
        }

    }


}





/*
.styled-select {
  background: url("../img/combobox_22x30.png") no-repeat 96% 0;
  height: 29px;
  overflow: hidden;
  width: 240px;
  border-radius: 5px;
  background-color: #00ad5f;
}

.styled-select select {
  background: transparent;
  border: none;
  font-size: 14px;
  height: 29px;
  padding: 5px;
  color: #ffffff;
  width: 110%;
  cursor: pointer;
}

*/

select {
    width: 100%;
    padding: 6px 10px;
    border: none;
    border-radius: var(--radius);
    background-color: #f1f1f1;
    color: var(--primary-text);
    background-color: #00ad5f;
    font-size: 1rem;
    box-shadow: 9px 11px 18px 2px rgba(0, 0, 0, 0.44);

    option {
        margin: 40px;
        background-color: #00ad5f;
        color: var(--primary-text);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
}



.full_screen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    padding: 15%;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    vertical-align: middle;
    text-align: center;
}

.GenericPage {
    width: 100%;
    max-width: 1200px;  
    margin: 0px auto 10px auto;
}

/* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */


@include tablet {

    body {
        margin-bottom: 0px;
    }

}

/************/

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}