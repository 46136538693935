.ButtonToTop {


  .toTOP {
    display: block;
    position: fixed;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    bottom: 55px;
    right: 20px;
    z-index: 9999999;
    border: none;
    outline: none;
    background-color: var(--secondary);
    color: var(--secondary-text);
    padding: 15px;
    border-radius: var(--radius);
    background-image: linear-gradient(to right, var(--secondary-light) 0%, var(--secondary) 100%);
    box-shadow: rgba(0, 0, 0, 0.52) 2px 2px 8px 0px;
    outline: 0;
    border: 0;
    opacity: 0.6;

    &:hover {
      background-image: unset;
      background-color: var(--secondary-light);
      // padding: 17px;
      opacity: 0.9;
    }

    .arrow {
      display: inline;
      font-size: 1.4rem;
      font-weight: bold;
      color: var(--secondary-text);
    }

  }



}