.page_giftcart {
    padding: 15px;

    .space {
        display: block;
        height: 25px;
        width: 100%;

    }

    .text {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-bottom: 15px;
    }

    .min_max {
        display: grid;

        grid-template: 
                 "head head head" 1fr
                 "val val val" 1fr;


        margin-top: 15px;
        text-align: center;

        .current_value{
            grid-area: val;
            font-size: 1.2rem;
            line-height: 1.2rem;
            margin-bottom: 15px;
            text-align: centers;
            font-weight: 600;
    
        }
    


        .min_price {
            width: auto;
            font-size: 1.2rem;
            text-align: right;
            padding-right: 15px;
            margin: 0px;
        }

        .max_price {
            width: auto;
            font-size: 1.2rem;
            text-align: left;
            padding-left: 15px;
        }
    }


    .preset_value {
        display: inline-block;
          
        //FIX background: var(--secondary) url('/images/core/giftcard_background_120x60.png') no-repeat scroll 0% 0%;
        background-size: 100% 100%;

        color: var(--secondary-text);
        width: 120px;
        height: 60px;
        cursor: pointer;
        margin: 0px 30px 30px 0px;
        border-radius: var(--radius);
        position: relative;
        box-shadow: 3px 3px 10px 1px #000000;
        opacity: 0.9;
        user-select: none;

        &:hover{
            opacity: 1;
            box-shadow: 3px 3px 10px 2px #000000;
        }


        p {
            text-align: center;
            vertical-align: middle;
            position: relative;
            width: 100%;
            height: 100%;
            font-size: 1.5rem;
            margin: auto auto;
            line-height: 60px;
          
          
        }
    }

    .slidecontainer {
        width: 100%;
        text-align: center;
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        cursor: pointer;
        box-shadow: 3px 3px 10px 1px #000000;
        
        &:hover {
            opacity: 1;
        }
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #4CAF50;
        cursor: pointer;
        
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: var(--secondary-light);
        cursor: pointer;
        
        box-shadow: 3px 3px 10px 1px #000000;
    }


}