@import '../../../styles/mixins.scss';

.OilsCardHolder {

    display: block;
    text-align: left;

    .letter_list {
        width: 100%;
        max-width: 100vw;
        margin-top: 10px;
        padding: 5px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .link {
            margin: 5px 5px;
            padding: 5px;
            min-width: 33px;
            text-align: center;
            width: fit-content;
            border-radius: var(--radius);


            &:hover {

                background-color: var(--secondary-light);

            }

        }

    }

    .letter_holder {
        padding: 10px;

        .oilsHolder {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            gap: 20px 15px;
        }


        .letter {
            width: 100%;
            font-weight: bold;
            margin-top: 10px;
            width: 100%;
            font-weight: bold;
            height: 3rem;
            line-height: 3rem;
            font-size: 2rem;
        }



    }


    @include mobile {

        .letter_holder {
            .letter {

                text-align: center;

            }

            .oilsHolder {
                gap: 20px 0px;
                justify-content: center;
            }
        }
    }

}