@import '../../../../styles/mixins.scss';

.PageContentImageCmp {
    display: block;
    width: 100%;
    height: 200px;
    max-height: 200px;
    text-align: center;
    margin: 0px auto 0px auto;
    padding: 0px;
    box-shadow: 3px 3px 10px 1px #000000;
    border-radius: 0px 0px var(--radius) var(--radius);
    overflow: hidden;

    .img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        border-radius: 0px 0px var(--radius) var(--radius);
    
    }


 
    @include tablet {
        height: auto;
        width: 100%;
        .img {
            height: auto;
        }
    }



}